import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import servicesImg from '../../../assets/graphics/services-icon.png';

class ServicesSection extends React.Component {

    render() {
        return (
            <section className="journeySection" id="services">
                <img src={servicesImg} className="tabletIcon" alt="Services Icon" />
                <Container className="sectionContent">
                    <Row className="sectionRow">
                        <Col xs={12} sm={9} md={9}>
                            <div className="mobileIconWrapper">
                                <h4 className="titleText">HIRING</h4>
                                <img src={servicesImg} className="mobileIcon" alt="Services Icon" />
                            </div>
                            <h4 className="missionText">You can be a part of the change you want to see in the world by hiring graduates from AnnieCannons.</h4>
                            <p className="paragraphText">By hiring our  team, you support not only jobs for survivors of human trafficking, but also an increase in inclusion and diversity in tech.</p>
                        </Col>
                        <Col xs={12} sm={3} md={3} className="imageContainer">
                            <img src={servicesImg} alt="Services Icon" />
                        </Col>
                    </Row>
                </Container>
                <div className="homeButtonWrapper">
                    <a href="/get-involved/become-a-hiring-partner"><button className="purpleBtns">Become a hiring partner</button></a>
                    <a href="/get-involved/contact-us"><button className="purpleBtns">Contact Us</button></a>
                </div>
            </section>
        )
    }
}

export default ServicesSection;