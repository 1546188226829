import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// Assets
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

function Button(props) {
	const {
		label,
		variant,
		darkMode,
		disabled,
		linkStyle,
		icon,
		iconSize,
		ariaConfig,
		selected,
		svg,
	} = props;

	// Button click event
	const handleClick = (event) => {
		console.log('button was clicked!', event.target);
	};

	const btnClass = classNames({
		[`ac-${linkStyle ? 'link' : 'button'}`]: true,
		'-dark-mode': darkMode,
		[`-${variant}`]: variant,
		'is-disabled': disabled,
		'-icon': (icon || svg) && !label,
		'is-selected': selected,
		'has-arrow': variant === 'bare',
	});

	return (
		<button
			className={btnClass}
			tabIndex={disabled ? -1 : 0}
			aria-disabled={disabled}
			onMouseDown={disabled ? (e) => e.preventDefault() : undefined}
			onClick={handleClick}
			{...ariaConfig}>
			{label}
			{icon && <FontAwesomeIcon icon={icon}></FontAwesomeIcon>}
			{svg}
			{variant === 'bare' && (
				<FontAwesomeIcon icon={faArrowRight} size={iconSize}></FontAwesomeIcon>
			)}
		</button>
	);
}

Button.propTypes = {
	// Adds aria attributes
	ariaConfig: PropTypes.object,
	// Use on dark backgrounds
	darkMode: PropTypes.bool,
	// Button is unclickable/unusable
	disabled: PropTypes.bool,
	// Button with icon
	icon: PropTypes.object,
	// Button label text
	label: PropTypes.string,
	// Different variations of a button
	variant: PropTypes.oneOf(['primary', 'secondary', 'bare', 'nav']),
	// Allow button to be styled as a Style Guide link
	linkStyle: PropTypes.bool,
	// Button is active/selected
	selected: PropTypes.bool,
	// Button with svg as an icon
	svg: PropTypes.object,
};

Button.defaultProps = {
	ariaConfig: null,
	darkMode: false,
	disabled: false,
	icon: null,
	label: '',
	variant: 'primary',
	linkStyle: false,
	selected: false,
	svg: null,
};

export default Button;
