import React from "react";
//import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
//import 'font-awesome/css/font-awesome.min.css';
//import StudentInfoModal from '../StudentInfoModal.js'
//import ReactModal from 'react-modal';
// import WhiteX from '../../Assets/images/close-icon-white.png';
import intakeImg from '../../../assets/graphics/intake-icon.png'

class IntakeSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }


  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <section className="journeySection" id="intake">
        <img src={intakeImg} className="tabletIcon" alt="Intake Icon" />
        <Container className="sectionContent">
          <Row className="sectionRow">
            <Col xs={12} sm={9} md={9}>
              <div className="mobileIconWrapper">
                <h2 className="titleText">ADMISSIONS</h2>
                <img src={intakeImg} className="mobileIcon" alt="Intake Icon" />
              </div>

              <h4 className="missionText">At AnnieCannons, we believe that economic opportunity is the key to breaking cycles of abuse and exploitation.</h4>
              <p className="paragraphText">AnnieCannons delivers a comprehensive training and workforce development program that supports our participants in accessing high-skills technology training, becoming workforce-ready, and having expanded opportunities to access careers and become economically independent.</p>
              <p className="paragraphText">Applying to AnnieCannons is the first step in building a new career.</p>

            </Col>
            <Col xs={12} sm={3} md={3} className="imageContainer">
              <img src={intakeImg} alt="Intake Icon" />
            </Col>
          </Row>
        </Container>
        <div
          className="homeButtonWrapper"
        >

          <a href="https://anniecannons.org/admissions" target="_blank" rel="noreferrer">
            <button className="purpleBtns">The Admissions Process</button>
          </a>
          {/* <button onClick={this.handleOpenModal} className="purpleBtns">Our Students</button>  */}
          {/* <a href="https://anniecannons.org/admissions"><button className="purpleBtns">Apply!</button></a> */}
        </div>

        {/* <ReactModal
          isOpen={this.state.showModal}
          contentLabel="AnnieCannons Student Info"
          ariaHideApp={false}
          overlayClassName="videoPlayer"
        >
          <StudentInfoModal
            closeModal={this.handleCloseModal}
          />
        </ReactModal> */}
      </section>
    )
  }
}

export default IntakeSection;
