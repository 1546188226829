import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
//import Button from '../../components/button/button';
import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
// Assets
import Wave3White from '../../assets/waves/wave-3-white.svg';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
import webAppIcon from '../../assets/photos/LisaHealth-Case-Study.jpg';
import coding from '../../assets/photos/coding.jpg';
import mission1 from '../../assets/photos/mission-1.png';
import aclearning from '../../assets/photos/AC-learning.jpg';
//import program from '../../assets/graphics/ACTrainingProgram.png';

import admission from '../../assets/graphics/admission2024.png';

import courseSchedule from '../../assets/graphics/courses2025.png';
//import clipboard from '../../assets/graphics/clipboard.png';

// import mobileAppIcon from '../../assets/photos/EasyTro-Case-Study.png';
// import websiteIcon from '../../assets/photos/Change_A_Path_Case_Study.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHeadset,
	//faCodeBranch,
} from '@fortawesome/free-solid-svg-icons';



const Admissions = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ac-caseStudies">
			<Navigation selected='our services'></Navigation>
			<section className="overFlowHide ac-caseStudies-hero" aria-labelledby="hero-heading">
				<div className="ac-caseStudies-hero-container">
					<h1 className="ac-caseStudies-heading" id="hero-heading">Admissions</h1>
					{/* <p className="desktopWidth45Percent ac-caseStudies-text">Join our program!</p> */}
				</div>
				<Star className="ac-caseStudies-hero-star"></Star>
				<Star className="ac-caseStudies-hero-star"></Star>
				<Star className="ac-caseStudies-hero-star"></Star>
			</section>
			<section
				className="ac-admissions-apps-section"
				aria-labelledby="apps-heading">
				<div className="ac-caseStudies-apps-section-container">
					<div className="ac-caseStudies-apps-content">
						<div className='desktopFlexRow alignItemsCentered  mobileColumnReversed appsDivs'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=''>
								{/* <h2 className="ac-caseStudies-subheading">Mentorship</h2> */}
								<p className="ac-caseStudies-text" style={{border: '3px solid black', padding: '5px', textAlign: 'center'}}><strong>Applications for Winter 2025 classes are closed. We will open applications for our Spring 2025 class in February 2025.</strong></p>
								<p className="ac-caseStudies-text">AnnieCannons offers a comprehensive training and workforce development program which provides high-skilled technology training in software engineering, equipping individuals who have experienced human trafficking with workforce-ready skills and opportunities for a sustainable career in the tech industry.</p>
                                <p className="ac-caseStudies-text">Our curriculum is taught using trauma-informed practices and provides individual support to aid in student persistence throughout our program. Through training and project-based learning, our students are capable and qualified to enter and succeed in the tech workforce.</p>
								{/* <CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/our-services/case-studies/easyTro'}></CustomLink> */}
							</div>
							{/* <img src={mobileAppIcon} className='appImages webApp' alt="app" /> */}
						</div>

						<div className=' desktopFlexRow alignItemsCentered  mobileColumnReversed appsDivs  list-criteria'>
							<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">What you need to know before applying:</h2>
								<ul><li>You must be fluent in English as programs are only offered in English at this time. </li>
                                <li>You will be in a shared, virtual educational space with up to 15 other individuals. </li>
                                <li>Social topics that can be sensitive to some folks may arise in conversation. </li>
                                <li>You may use a pseudonym for the duration of your time in the coding program. </li>
                                <li>You must be available Monday through Friday for five hours daily of virtual classroom instruction. (8:45am to 2:15pm PST, 11:45am to 5:15pm EST, 10:45am to 4:15pm CT)</li>
                                <li>People of any skill level are invited to apply so long as you have a drive to learn.</li>
                                <li>The program is no-cost for students. If admitted to the program, a computer and hotspot (if needed) will be provided.</li></ul>
 

								{/* <CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/our-services/case-studies/lisa-health'}></CustomLink> */}
							</div>
							<img src={webAppIcon} className='appImages mobileApp' alt="Mobile app" />

							<Star className="ac-caseStudies-apps-2-star"></Star>
							<Star className="ac-caseStudies-apps-2-star"></Star>
						</div>
                        <div className=' desktopFlexRow alignItemsCentered  mobileColumnReversed appsDivs  list-criteria'>
							<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">Eligibility Criteria:</h2>
								<ul><li>18 years or older OR will be 18 by the start date of the program. </li>
                                <li>Identifies as an individual who has experienced human trafficking. </li>
                                <li>Currently has stable fundamental needs in place (housing, food, a managed care plan for any physical health, mental health and substance use needs). </li>
                                <li>Able to commit to the required class schedule. </li>
								<li>Currently residing in the United States. </li>
								<li>Seriously committed to pursuing a career in the tech industry</li>
								<li><strong style={{textDecoration: 'underline'}}>Note:</strong> Individuals who do not have work authorization in the United States are welcome to participate in advance modules but will not be able to receive compensation. Our team is committed to working with partners to support students’ as they apply for work authorization status in the US.</li>
                                </ul>
							</div>
							<img src={coding} className='appImages mobileApp' alt="Mobile app" />

							<Star className="ac-caseStudies-apps-2-star"></Star>
							<Star className="ac-caseStudies-apps-2-star"></Star>
						</div>

                      

                        <div className=' desktopFlexRow alignItemsCentered  mobileColumnReversed appsDivs  list-criteria'>
							<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">Characteristics of Students who Enter the Program:</h2>
								<ul><li>A drive to learn new things and continue to grow in self-awareness. </li>
                                <li>Enjoyment of puzzles, problem solving, and understanding cause and effect. </li>
                                <li>Likes to find patterns, problems, and solutions. </li>
                                <li>Attentive to directions and details. </li>
                                <li>Commitment to community care and openness to accountability for any harm. </li>
                                <li>Managed physical health, control over substance use, active recovery/counseling plan. </li>

                                </ul>
								{/* <CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/our-services/case-studies/lisa-health'}></CustomLink> */}
							</div>
							<img src={mission1} className='appImages mobileApp' alt="Mobile app" />

							<Star className="ac-caseStudies-apps-2-star"></Star>
							<Star className="ac-caseStudies-apps-2-star"></Star>
						</div>

                        <div className='appsDivs list-criteria'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className='flexColumnCentered'>
								{/* <h2 className="ac-caseStudies-subheading">Our Program</h2> */}
                                <img src={courseSchedule} className=' webApp' alt="app" />

								{/* <p className="ac-caseStudies-text"> <strong className="bold">Tech Career Foundations (4 weeks):</strong> Students will gain increased skills, understanding, and confidence in securing employment in tech industry jobs. This module solidifies critical 21st century digital workplace skills and bridges the gap in understanding the tech industry, the software lifecycle, and the foundations for programming.</p>
                                <p className="ac-caseStudies-text"> <strong className="bold">Introduction to Programming (15 weeks):</strong>
Students will build the foundation of knowledge to be successful in software development and programming. Students will increase skills, understanding, and confidence in algorithmic thinking, web principles, and problem solving. Prerequisite: Tech Career Foundations.</p>
                                <p className="ac-caseStudies-text"><strong className="bold">Advanced Modules in Front-End or Back-End Development (16 weeks):</strong>
Advanced learning modules incorporate project-based learning which allows students to apply skills in active client products where they can grapple with coding challenges, discover and test solutions, and interact with structured teams. AnnieCannons provides stipends and benefits to help offset the costs of living while students are engaged in advanced learning modules. Prerequisites: Tech Career Foundations and Introduction to Programming.</p> */}
                               



								<div style = {{marginTop: '30px'}}>
								<CustomLink
									
									buttonStyle
									buttonVariant="primary"
									external={false}
									label={'Learn more about the Classes'}
									to={'/our-work/coding-training-program'}></CustomLink></div>
							</div>
						</div>

                        <div className=' alignItemsCentered  mobileColumnReversed appsDivs list-criteria'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=''>
								<h2 className="ac-caseStudies-subheading">Course Schedule</h2>
								
                            
							</div>
							<img src={admission} className=' webApp' alt="app" width="350px !important" />
                            {/* <div className='desktopFlexRow mobileTextAlignCenter ' style={{justifyContent: 'center'}}>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLScv9qzUv_CGWvhzf0zc9Rr15vHPMYy3CUp46NoUrX75mS6P6A/viewform" target="_blank" rel="noreferrer"><Button label="Apply Now!" variant="primary"  ></Button></a>
							</div> */}
						</div>

						<div className=' alignItemsCentered  mobileColumnReversed appsDivs list-criteria'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=''>
								<h2 className="ac-caseStudies-subheading">Student Considerations</h2>
								
                            
							</div>
						
							<div className="video-responsive">
								<iframe width="560" height="315" src="https://www.youtube.com/embed/fCn7rHkK6No?si=7dUieJBH8Qc84u63" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
							
						</div>

						<div className=' desktopFlexRow alignItemsCentered  mobileColumnReversed appsDivs list-criteria'>
						<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">Other Important Things To Know (Advanced Classes)</h2>

								<ul><li>Students can currently take up to three advanced modules if they choose </li>
                                <li>Students who complete one advanced module may keep the computer provided to them by AnnieCannons</li>
                                <li>Students who choose to participate in the advanced modules and are authorized to work in the US will become eligible for compensation ($20 per hour) and insurance benefits </li>
                                <li>You may use a pseudonym for the duration of your time in the coding program. </li>
                                <li>Individuals who do not have work authorization in the United States are welcome to participate in advance modules but will not be able to receive compensation. Our team is committed to working with partners to support students’ as they apply for work authorization status in the US.</li></ul>
 

								{/* <CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/our-services/case-studies/lisa-health'}></CustomLink> */}
							</div>
							<img src={aclearning} className='appImages mobileApp' alt="Mobile app" />

							<Star className="ac-caseStudies-apps-2-star"></Star>
							<Star className="ac-caseStudies-apps-2-star"></Star>
						</div>

						{/* <div className='alignItemsCentered  mobileColumnReversed appsDivs list-criteria'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=' desktopFlexRow alignItemsCentered  mobileColumnReversed appsDivs'>
								
								<div><h2 className="ac-caseStudies-subheading">Application Process</h2>
								<ul>
									<li>Complete the Application Form</li>
									<li>Monitor your email address for application confirmation email and invitation to interview</li>
									<li>Attend virtual interview with AnnieCannons team members. You will receive the interview questions in advance to assist in preparation. There is no dress code for interview. </li>
									<li>Monitor email address for admissions decision</li>
									<li>Complete all new student forms and review student handbook</li>
								</ul>
								</div>
								<img src={clipboard} className='appImages mobileApp' alt="app" />
							</div>
							
							<div className='desktopFlexRow mobileTextAlignCenter ' style={{justifyContent: 'center'}}>
                                <a href="https://anniecannons.embark.com/apply/2024-winter" target="_blank" rel="noreferrer"><Button label="Apply Now!" variant="primary"  ></Button></a>
							</div> 
						</div> */}


                        
					</div>
                    

				</div>

				<div
					className="ac-caseStudies-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
			<section
				className="ac-caseStudies-moreInfo"
				aria-labelledby="moreInfo-heading">
				<div className="ac-caseStudies-moreInfo-container">
					<h2 className="marginBottom40 ac-caseStudies-subheading moreInfo" id="moreInfo-heading">
						Get more info
					</h2>
					<div className="ac-caseStudies-moreInfo-content" style={{justifyContent: 'center'}}>
						{/* <div className='positionRelative'>
							<FontAwesomeIcon icon={faCodeBranch} className='moreInfoIcon' />
							<p className="ac-caseStudies-text">Application Form</p>
							<div className='absoluteBottom'>
                                 <a href="https://anniecannons.embark.com/apply/2024-winter" target="_blank" rel="noreferrer"><Button label="Application" variant="primary"  ></Button></a> 
								{/*<Button label="Application" variant="primary"  ></Button> 
							</div>
						</div>*/}
						<div>
							<FontAwesomeIcon icon={faHeadset} className='moreInfoIcon' />
							<p className="ac-caseStudies-text">Learn about our program.</p>
							<CustomLink
								to='/our-work/coding-training-program'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Learn More"
								internal
								className="ac-caseStudies-consult-btn"></CustomLink>
						</div> 
						<div>
							<FontAwesomeIcon icon={faHeadset} className='moreInfoIcon' />
							<p className="ac-caseStudies-text">Connect with our team.</p>
							<CustomLink
								to='/get-involved/contact-us'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Contact Us"
								internal
								className="ac-caseStudies-consult-btn"></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default Admissions;
