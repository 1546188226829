import React from 'react';
import CaseStudyTemplate from './caseStudyTemplate';
import heroImg from '../../assets/photos/cap-heroImg.png';
import clientTextLogo from '../../assets/logos/client/Change-a-Path.svg';
import capImage from '../../assets/photos/CAP-screen-2.png';
import capImagePerson from '../../assets/photos/CAP-hero.jpeg';


function ChangeAPath() {

    return (
        <CaseStudyTemplate
            title='Change A Path Website'
            subTitle='Starting from the ground up, AnnieCannons worked collaboratively with Change a Path to develop a website that prioritized clear communication, ease of navigation, and a simple payment function for donors.'
            heroImg={heroImg}
            overviewText='Change a Path is a volunteer donor circle that gives grants to not-for-profit organizations working to fight sex trafficking. They needed a new website to help them better communicate their objectives to members, donors and partners. AnnieCannons’ commitment to empowering survivors of human trafficking made us the perfect team to build it.'
            clientTextLogo={clientTextLogo}
            logoAltText={'Change A Path'}
            client='Change A Path'
            industry='Nonprofit'
            projectType='Website'
            impactMetricNumber1='20%'
            impactMetricText1='Key metric that illustrates success or human impact'
            impactMetricNumber2='18'
            impactMetricText2='Key metric that illustrates success or human impact'
            impactMetricNumber3='24'
            impactMetricText3='Key metric that illustrates success or human impact'
            impactQuote='We had a functional website before but AnnieCannons really took it to the next level. They helped us to professionalize the site by simplifying the platform and communicating our message more clearly, and more positively. -Shannon Rogers,
            Founder & President,
            Change a Path'
            problem='The donors of the Change a Path Circle started as a close group of local activists, supporting anti-trafficking nonprofits. Through a diligent, deep vetting process, they’d funded a portfolio of high-impact nonprofits, both in the U.S. and around the world. The Change a Path board wanted to do even more. To expand their circle and extend their reach, they needed a web-based method for more people to join the group, become involved remotely, and make donations easily online.'
            problemDifficulty='[Show how the probelm was particulary "tough," "complex," "thorny," "challenging," etc.]'
            problemConstraints='[Feel free to highlight any other relevant constraints.]'
            problemImportance='[Convey why this problem or overall issue is important on a human level.]'
            process='To address this challenge, AnnieCannons worked directly with the Change a Path leadership to design web site aesthetics and functions that were both affordable and delightful. Since the site’s launch, Change a Path has seen fresh donations and enquiries from prospective benefactors across the globe.'
            processImg1={heroImg}
            processImg2={capImage}
            solution='On top of helping Change a Path pursue its mission, this job enabled AnnieCannons’ graduate developer to add a great piece to her emerging portfolio within a few months of graduating from our survivors’ coding bootcamp. That was just the first step on her personal path toward a robust portfolio of full-stack applications. For AnnieCannons, the project helped showcase just what our developers are capable of. This was the second website the developer had built, and the number of positive referrals we subsequently received was a testament to the quality of the product. Even better, the engagement with Change a Path and resulting referrals enabled AnnieCannons to help several more survivors rebuild their lives.'
            featuresAndDeliverables={
                [
                    'Brainstormed ways to better communicate Change a Path’s mission and value.',
                    'Designed a simple, easy-to-use integration with PayPal, making it straightforward for donors to give money.',
                    'Drafted text for the site.',
                    'Built a user-friendly membership application form.',
                    'Trained the Change a Path team to use the Wordpress interface to aggregate leads and update partner and team contacts.',
                ]
            }
            solutionImg1={capImagePerson}
            solutionImg2={heroImg}
        />
    )
}

export default ChangeAPath;