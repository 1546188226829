import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
//import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
import Button from '../../components/button/button';

// Assets
import Wave3White from '../../assets/waves/wave-3-white.svg';
//import Wave1 from '../../assets/waves/wave-1-white.svg';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
//import mobileAppIcon from '../../assets/icons/mobileapp-our-services-page.svg';
//import websiteIcon from '../../assets/icons/website-our-services-page.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHeadset,
} from '@fortawesome/free-solid-svg-icons';
import digitalLiteracy from '../../assets/graphics/curriculum1.jpg';
import curriculum18 from '../../assets/graphics/curriculum18.jpg';
import coding from '../../assets/photos/coding.jpg';
import curriculum11 from '../../assets/graphics/curriculum11.jpg';
//import curriculum14 from '../../assets/graphics/curriculum14.jpg';
//import classroomIcon1 from '../../assets/graphics/classroomIcon1.png';
import Wave3Flipped from '../../assets/waves/wave-3-white-flipped.svg';
//import ACPerson1 from '../../assets/graphics/ACPerson1.png';
//import ACPerson2 from '../../assets/graphics/ACPerson2.png';
//import ACPerson3 from '../../assets/graphics/ACPerson3.png';


const CodingTrainingProgram = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ac-whatWeBuild">
			<Navigation selected='our services'></Navigation>
			<section className="overFlowHide ac-whatWeBuild-hero" aria-labelledby="hero-heading">
				<div className="ac-whatWeBuild-hero-container">
					<h1 className="ac-whatWeBuild-heading" id="hero-heading">Learning to code</h1>
					<p className="desktopWidth45Percent ac-whatWeBuild-text">Our multi-pronged program starts with tech career foundations and teaches participants the fundamentals of software development. </p>
				</div>
				<Star className="ac-whatWeBuild-hero-star"></Star>
				<Star className="ac-whatWeBuild-hero-star"></Star>
				<Star className="ac-whatWeBuild-hero-star"></Star>
			</section>
			<section
				className="ac-our-mission-our-story"
				aria-labelledby="our-story-heading">
				<div className="ac-our-mission-our-story-container">
					<div className="ac-our-mission-content">
						<h2 className="ac-our-mission-subheading" id="engineers-heading">
							Our approach
						</h2>
						<p className="ac-our-mission-body">
						Our curriculum is divided up into learning modules that allow survivors to choose their own pathways. Participants begin learning computer basics and quickly progress into fundamentals of computer programming. 
						<br />
							<br />
							Participants receive more than 625 hours of classroom training, engage in hands-on project learning, and have access to individualized help and 1:1 coaching, graduate instructors and mentors, and other services customized to the unique learning and support needs of survivors and underserved populations, especially BIPOC women. 

						</p>
						{/* <a href="https://anniecannons.org/our-work/our-curriculum" ><Button label="Learn More About Our Curriculum" variant="primary" ></Button></a> */}
					</div>
					<img src={curriculum11} alt="Books and Pencils"></img>
				</div>

				<div
					className="ac-our-mission-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
			{/* <section
				className="ac-our-mission-programs"
				aria-labelledby="programs-heading">
				<div className="ac-our-mission-programs-container">
					<h2 className="marginBottom40 ac-our-mission-subheading" id="programs-heading">
						Our program pillars
					</h2>
					<div className="ac-our-mission-programs-content">
						<div>
							<img src={ACPerson2} className='appIcons websites' alt="Websites"></img>
							<h3>
								Flexing your genius muscles
							</h3>
							<p className="ac-our-mission-body">
								We believe that .
							</p>
						</div>
						<div>
						<img src={ACPerson1} className='appIcons websites' alt="Websites"></img>
							<h3>
								Healing-centered design
							</h3>
							<p className="ac-our-mission-body">
								Graduates get on-the-job experience building software for the real world.
							</p>
						</div>
						<div>
						<img src={ACPerson3} className='appIcons websites' alt="Websites"></img>

							<h3>
								Lifelong learning
							</h3>
							<p className="ac-our-mission-body">
								Our engineers contribute to projects that shape social good and social justice, like our ReferAll project.
							</p>
						</div>
					</div>
				</div>
				<div
					className="ac-our-mission-wave"
					style={{ backgroundImage: `url(${Wave1})`, bottom: 0 }}></div>
			</section> */}
				
            <section className="positionRelative ac-our-process-how">
				<Star className="ac-our-process-how-star"></Star>
				<Star className="ac-our-process-how-star"></Star>
				<Star className="ac-our-process-how-star"></Star>
				<div className="ac-our-process-how-container">
					<h2 className="ac-our-process-how-subheading" id="-heading">
						What we teach
						</h2>
					<p className="ac-our-process-body">We combine the talents of an emerging workforce with seasoned industry experts to develop and deliver solutions that are high-quality, affordable and impact-driven.</p>
				</div>
				<Star className="ac-our-process-how-star"></Star>
				<Star className="ac-our-process-how-star"></Star>
				<Star className="ac-our-process-how-star"></Star>
				<div
					className="ac-our-process-wave"
					style={{ backgroundImage: `url(${Wave3Flipped})`, bottom: '-32px' }}>
				</div>
			</section>
            <section
				className="ac-whatWeBuild-apps-section"
				aria-labelledby="apps-heading">
				<div className="ac-whatWeBuild-apps-section-container">
					<div className="ac-whatWeBuild-apps-content">
						<div className='desktopFlexRow alignItemsCentered marginBottom100 mobileTextAlignCenter appsDivs'>
							<Star className="ac-whatWeBuild-apps-1-star"></Star>
							<Star className="ac-whatWeBuild-apps-1-star"></Star>
							<Star className="ac-whatWeBuild-apps-1-star"></Star>
							<img src={digitalLiteracy} className='appIcons webApp' alt="Web app"></img>
							<div className='desktopMarginLeft40'>
								<h2 className="ac-whatWeBuild-subheading">Tech Career Foundations</h2>
								<p className="ac-whatWeBuild-text">This phase is 4 weeks and students will gain increased skills, understanding, and confidence in securing employment in tech industry jobs. This module solidifies critical 21st century digital workplace skills and bridges the gap in understanding the tech industry, the software lifecycle, and the foundations for programming.</p>
							
							</div>
						</div>

						<div className='desktopFlexRow alignItemsCentered marginBottom100 mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<div className='desktopMarginRight40'>
								<h2 className="ac-whatWeBuild-subheading">Introduction to Programming</h2>
								<p className="ac-whatWeBuild-text">Students will build the foundation of knowledge to be successful in software development and programming. Students will increase skills, understanding, and confidence in algorithmic thinking, web principles, and problem solving. Prerequisite: Tech Career Foundations.</p>
								
							</div>
							<img src={curriculum18} className='appIcons mobileApp' alt="Mobile app"></img>

							<Star className="ac-whatWeBuild-apps-2-star"></Star>
							<Star className="ac-whatWeBuild-apps-2-star"></Star>
						</div>

						<div className='desktopFlexRow alignItemsCentered marginBottom100 mobileTextAlignCenter appsDivs'>
							<Star className="ac-whatWeBuild-apps-3-star"></Star>
							<Star className="ac-whatWeBuild-apps-3-star"></Star>
							<img src={coding} className='appIcons websites' alt="Websites"></img>
							<div className='desktopMarginLeft40'>
								<h2 className="ac-whatWeBuild-subheading">Advanced Modules</h2>
								<p className="ac-whatWeBuild-text">Advanced learning modules incorporate project-based learning which allows students to apply skills in active client products where they can grapple with coding challenges, discover and test solutions, and interact with structured teams. AnnieCannons provides stipends and benefits to help offset the costs of living while students are engaged in advanced learning modules. Prerequisites: Tech Career Foundations and Introduction to Programming.</p>
								
							</div>
						</div>
					</div>

				</div>

				<div
					className="ac-whatWeBuild-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
            <section
				className="ac-whatWeBuild-connect"
				aria-labelledby="connect-heading">
				<div className="ac-whatWeBuild-connect-container">
					<h2 className="marginBottom40 ac-whatWeBuild-subheading connect" id="connect-heading">
						Apply!
					</h2>
					<div className="ac-whatWeBuild-connect-content">
						<FontAwesomeIcon icon={faHeadset} className='connectIcon' />
						{/* <p className="ac-whatWeBuild-text">We will be launching our 2023 classes soon! Check back for more information.</p> */}
						<p className="ac-whatWeBuild-text">Learn how you can apply to the AnnieCannons Coding Training Program.</p>
						<a href='https://anniecannons.org/admissions'>
						<Button
							buttonStyle
							buttonVariant="primary"
							darkMode
							label="Learn More"
							internal
							className="ac-whatWeBuild-consult-btn"></Button></a>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default CodingTrainingProgram;
