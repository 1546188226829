import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Checkbox(props) {
	const {label} = props;

	return (
		<div className="ac-checkbox">
			<input className="ac-checkbox-input" type="checkbox"></input>
			<label className="ac-checkbox-label">{label}</label>
		</div>
	);
}

Checkbox.propTypes = {
	// Checkbox label text
	label: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
	label: '',
};

export default Checkbox;
