import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {dashCase} from '../../helpers/case/dashCase';

function Input(props) {
	const {required, label, placeholder, message, invalid} = props;

	const inputClass = classNames({
		'ac-input': true,
		'is-invalid': invalid,
	});

	return (
		<div className={inputClass}>
			<label
				className="ac-input-label"
				htmlFor={dashCase(`${label}-input-field`)}>
				{label}
				{required && <span> *</span>}
			</label>
			<input
				className="ac-input-field"
				onChange={props.onChange}
				name={props.name}
				id={dashCase(`${label}-input-field`)}
				placeholder={placeholder}
				aria-required={required}
				aria-describedby={message && dashCase(`${label}-message`)}></input>
			{message && (
				<p className="ac-input-field-message" id={dashCase(`${label}-message`)}>
					{message}
				</p>
			)}
		</div>
	);
}

Input.propTypes = {
	// Input has an error
	invalid: PropTypes.bool,
	// Input label text
	label: PropTypes.string.isRequired,
	// Message beneath input field
	message: PropTypes.string,
	// Input placeholder text
	placeholder: PropTypes.string,
	// Input field is required to be filled out
	required: PropTypes.bool,
};

Input.defaultProps = {
	invalid: false,
	label: '',
	message: '',
	placeholder: '',
	required: false,
};

export default Input;
