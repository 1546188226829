import React, { useState, useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
//Components
import Carousel from '../../components/carousel/carousel';
import Button from '../../components/button/button';
//import Carousel from '../../components/carousel/carousel';
import Navigation from '../../components/navigation/navigation';
import Footer from '../../components/footer/footer';
//import Button from '../../components/button/button';
import CustomLink from '../../components/custom-link/customLink';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
//Assets
//import Logo from '../../assets/graphics/ac-logo-black.svg';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
import Wave1 from '../../assets/waves/wave-1-white.svg';
import Wave3White from '../../assets/waves/wave-3-white.svg';
import Wave1OffBlack from '../../assets/waves/wave-1-offblack.svg';
import LauraPhoto from '../../assets/photos/team/laura-hackney-static.png';
import WaveOffWhite from '../../assets/waves/wave-actions-offwhite.svg';
import { ReactComponent as QuoteIcon } from '../../assets/graphics/quotes.svg';
import Mission1 from '../../assets/photos/mission-1.png';
import Mission2 from '../../assets/photos/mission-2.png';
import Mission3 from '../../assets/photos/mission-3.png';
import Mission4 from '../../assets/photos/mission-4.png';
import Mission5 from '../../assets/photos/mission-5.png';
import OurHistoryImg from '../../assets/photos/mission-6.png';
import TrainingIcon from '../../assets/icons/training.svg';
import EmploymentIcon from '../../assets/icons/employment.svg';
import IncubatorIcon from '../../assets/icons/incubator.svg';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faChartBar, faUsers } from '@fortawesome/free-solid-svg-icons';
import QuotesIcon from '../../assets/graphics/quotes.svg';

//import McGovern from '../../assets/logos/funder/McGovernLogo.jpeg';
//import Cisco from '../../assets/logos/funder/CiscoHoriz.png';
//import Motorola from '../../assets/logos/funder/MotorolaFoundationcolor.png';
//import Jensen from '../../assets/logos/funder/TheJensenProjectLogoPurple.png';
//import LisaHealth from '../../assets/logos/client/LisaHealth.svg';
//import Makeena from '../../assets/logos/client/Makeena.svg';
//import PCRC from '../../assets/logos/client/PCRC.svg';
// const homeSlides = [
// 	{
// 		image: LisaHealth,
// 		label: 'LisaHealth',
// 		title: 'AnnieCannons Client',
// 		description:
// 			'Our engineers saw the need for an app that addressed the complex reality of filing temporary restraining orders. They knew from experience what it was like to apply for legal protection against abuse. They understood the pitfalls and designed around them to create an effective app for families creating safety.',
// 		link: { label: 'See the case study', url: '/our-services/case-studies/lisa-health' },
// 	},
// 	{
// 		image: Logo,
// 		label: 'ACTrainee',
// 		title: 'AnnieCannons Trainee',
// 		description:
// 			'“When you’re around people for so long who don’t believe in you, you start to believe that you’re incapable. I’ve spent so much of my life having to figure out everything on my own and being in environments that were unsupportive to my overall well-being. Being in the AnnieCannons training program and their first national, all-virtual cohort, I’m now experiencing this amazing, compassionate environment where people not only see my potential, but also, people see how hard I’m working and how much I have to offer. It has elevated my healing journey beyond words. Because of the incredible support from everyone at AC, I’ve realized how powerful my ability is to utilize my tools, resources and my inner strength to creatively solve problems and overcome challenges.',
// 	},
// 	{
// 		image: Logo,
// 		label: 'ACGrad',
// 		title: 'AnnieCannons Graduate',
// 		description:
// 			"When I think about AnnieCannons, the first thing that comes to mind is 'true freedom'. I'm free to exist, think, and speak. I'm free to learn. Free to excel. Free to do whatever I want for the rest of my life.",
// 	},
// 	{
// 		image: Makeena,
// 		label: 'Makeena',
// 		title: 'AnnieCannons Client',
// 		description:
// 			'Our engineers saw the need for an app that addressed the complex reality of filing temporary restraining orders. They knew from experience what it was like to apply for legal protection against abuse. They understood the pitfalls and designed around them to create an effective app for families creating safety.',
// 		// link: {label: 'See the case study', url: '/case-study'},
// 	},
// 	{
// 		image: PCRC,
// 		label: 'PCRC',
// 		title: 'AnnieCannons Client',
// 		description:
// 			'Our engineers saw the need for an app that addressed the complex reality of filing temporary restraining orders. They knew from experience what it was like to apply for legal protection against abuse. They understood the pitfalls and designed around them to create an effective app for families creating safety.',
// 		// link: {label: 'See the case study', url: '/case-study'},
// 	},
// 	// {
// 	// 	image: CollegeMapper,
// 	// 	label: 'CollegeMapper',
// 	// 	description:
// 	// 		'Our engineers saw the need for an app that addressed the complex reality of filing temporary restraining orders. They knew from experience what it was like to apply for legal protection against abuse. They understood the pitfalls and designed around them to create an effective app for families creating safety.',
// 	// 	link: {label: 'See the case study', url: '/case-study'},
// 	// },
// ];
const ourTeamSlides = [
	{
		image: QuotesIcon,
		description:
			'Making the decision to attend the AnnieCannons coding boot camp was honestly a last resort for me and one of the best decisions I have ever made. They saw my potential, believed in me, had much patience, and taught me the skills I needed to become a successful software engineer. Which then provided me with the tools and income I needed to move towards a more safe, healthy, and stable life. For that I am forever grateful.',
		author: "Da'Kirah",
		age: '27',
	},
	{
		image: QuotesIcon,
		description:
			'I remember [when I] qualified to move forward in the admissions process. I could not believe it. It has [now] been about a year since I [became] a part of the AnnieCannons team. AnnieCannons gives us every reason to keep going, keep learning, and be our own boss by providing us with lifetime trainings [to] stay up-to-date with [the] skills required to be the BEST qualified developers we can be!',
		author: 'LethaLE',
		age: '24',
	},
	{
		image: QuotesIcon,
		description:
			'When I first came to AnnieCannons, I [doubted] myself and felt that I had no purpose at all. As a survivor of human trafficking, I thought that my life was over. AnnieCannons changed my life 180 degrees. I found new meaning and purpose [to] my existence, and never had I imagined that I could be that badass engineer that I always wanted to be.',
		author: 'Supergirl',
	},
	{
		image: QuotesIcon,
		description:
			"When I think about AnnieCannons, the first thing that comes to mind is 'true freedom'. I'm free to exist, think, and speak. I'm free to learn. Free to excel. Free to do whatever I want for the rest of my life.",
		author: 'Morys',
		age: '33',
	},
];

function OurPurpose() {
	const [timeLineAlignment, setTimeLineAlignment] = useState('alternate')

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Update timeline alignment for mobile or desktop view
	const handleResize = () => {
		if (window.innerWidth <= 767) {
			setTimeLineAlignment('left');
		} else {
			setTimeLineAlignment('alternate');
		}
	};
	// useEffect to add an event listener to detect screen resize
	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return function cleanup() {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<div className="ac-our-mission">
			<Navigation selected='our purpose'></Navigation>
			<section className="overFlowHide ac-our-mission-hero" aria-labelledby="hero-heading">
				<div className="ac-our-mission-hero-container">
					<h1 className="ac-our-mission-heading" id="hero-heading">
						Our mission
					</h1>
					<p className="desktopWidth45Percent ac-our-mission-body">
						To train, prepare, and connect individuals who have experienced human trafficking to sustainable careers in tech.
					</p>
				</div>
				<Star className="ac-our-mission-hero-star"></Star>
				<Star className="ac-our-mission-hero-star"></Star>
				<Star className="ac-our-mission-hero-star"></Star>
			</section>
			<section
				className="ac-our-mission-our-story"
				aria-labelledby="our-story-heading">
				<div className="ac-our-mission-our-story-container">
					<div className="ac-our-mission-content">
						<h2 className="ac-our-mission-subheading" id="engineers-heading">
							Our story
						</h2>
						<p className="ac-our-mission-body">
							Our namesake, Annie Jump Cannon (1863-1941), was a brilliant astronomer, but was underestimated during her lifetime. In spite of pervasive gender discrimination, she persevered and made revolutionary scientific discoveries that shaped our modern world.
						<br />
							<br />
						Our participants faced similar resistance in their lives, but they didn’t stop being brilliant or inventive. Through our organization, they are accessing the skills, resources and opportunities they deserve to become trailblazing tech pioneers, just like Annie.
						</p>
						<a href="https://youtu.be/z-gUN1HbRW4" target="_blank" rel="noreferrer"><Button label="Learn More About Our Name" variant="primary" ></Button></a>

					</div>
					<img src={Mission1} alt="Stary night computer with hand"></img>
				</div>
				<div>
				</div>

				<div
					className="ac-our-mission-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
			<section
				className="ac-our-mission-programs"
				aria-labelledby="programs-heading">
				<div className="ac-our-mission-programs-container">
					<h2 className="marginBottom40 ac-our-mission-subheading" id="programs-heading">
						Our focus
					</h2>
					<div className="ac-our-mission-programs-content">
						<div>
							<img src={TrainingIcon} alt="Illustrative icon with a computer screen and an individual pointing at words on a whiteboard on screen"></img>
							<h3>
								Coding Training
							</h3>
							<p className="ac-our-mission-body">
								We provide training in advanced full-stack technologies.
							</p>
						</div>
						<div>
							<img src={EmploymentIcon} alt="Illustrative icon of a briefcase"></img>
							<h3>
								Job Readiness
							</h3>
							<p className="ac-our-mission-body">
								Participants are connected with mentors and job readiness workshops.
							</p>
						</div>
						<div>
							<img src={IncubatorIcon} alt="Illustrative icon with a hand holding a pile of dirt with a small plant growing"></img>
							<h3>Community Connection							</h3>
							<p className="ac-our-mission-body">
							Graduates get on-the-job experience building software for the real world.							</p>
						</div>
					</div>
				</div>
				<div
					className="ac-our-mission-wave"
					style={{ backgroundImage: `url(${Wave1})`, bottom: 0 }}></div>
			</section>
			<section className='ac-our-mission-timeline-container' style={{ backgroundImage: `url(${OurHistoryImg})` }}>
				<div className="ac-our-mission-timeline">
					<h2 className="marginBottom40 ac-our-mission-history-subheading" id="history-heading">Our timeline</h2>

					<div className="ac-our-mission-timeline-content">
						<Timeline align={timeLineAlignment}>
							<TimelineItem>
								<TimelineOppositeContent className='underSmHidden'>
									<div className="ac-our-mission-history-timeline-button">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="The Start"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className="ac-our-mission-history-timeline-dot" />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent className="ac-our-mission-timeline-img">
									<div className="ac-our-mission-history-timeline-button overSmHidden">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="2015"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>

									<Typography className="ac-our-mission-history-timeline-event">AnnieCannons is founded and conducts its first coding boot camp for trafficking survivors in San Francisco, CA</Typography>
									<br />
									<img src={Mission2} alt="Three smiling people with long hair on their computers"></img>
								</TimelineContent>
							</TimelineItem>
							<TimelineItem>
								<TimelineOppositeContent className='underSmHidden'>
									<div className="ac-our-mission-history-timeline-button">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="Partnerships"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className="ac-our-mission-history-timeline-dot" />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent className="ac-our-mission-timeline-img">
									<div className="ac-our-mission-history-timeline-button overSmHidden">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="2019"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>

									<Typography className="ac-our-mission-history-timeline-event">AnnieCannons expands services to partner with organization and survivor leadership groups around the country.</Typography>
									<br />
									<img src={Mission3} alt="Three smiling people with long hair on their computers"></img>
								</TimelineContent>
							</TimelineItem>
							<TimelineItem>
								<TimelineOppositeContent className='underSmHidden'>
									<div className="ac-our-mission-history-timeline-button">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="Student Success"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot className="ac-our-mission-history-timeline-dot" />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent className="ac-our-mission-timeline-img">
									<div className="ac-our-mission-history-timeline-button overSmHidden">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="2020"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>

									<Typography className="ac-our-mission-history-timeline-event">In 2022, AnnieCannons completed its eigth boot camp, built its 100th product, and deployed a new program design to support job readiness.</Typography>
									<br />
									<img src={Mission4} alt="Three smiling people with long hair on their computers"></img>
								</TimelineContent>
							</TimelineItem>
							<TimelineItem>
								<TimelineOppositeContent className='underSmHidden'>
									<div className="ac-our-mission-history-timeline-button">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="The Future"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>
								</TimelineOppositeContent>
								<TimelineSeparator className='lastTimelineSeparator'>
									<TimelineDot className="ac-our-mission-history-timeline-dot" />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent className="ac-our-mission-timeline-img">
									<div className="ac-our-mission-history-timeline-button overSmHidden">
										<CustomLink
											buttonStyle
											buttonVariant="secondary"
											to="#"
											label="The Future"
											darkMode
											id="ac-our-mission-history-timeline-button"
										/>
									</div>

									<Typography className="ac-our-mission-history-timeline-event">AnnieCannons is growing its fully-virtual training, extending participation to more survivors nationwide.</Typography>
									<br />
									<img src={Mission5} alt="Three smiling people with long hair on their computers"></img>
								</TimelineContent>
							</TimelineItem>
						</Timeline>
					</div>
				</div>
				<div
					className="ac-our-mission-wave"
					style={{ backgroundImage: `url(${Wave1OffBlack})`, bottom: 0 }}></div>
			</section>
			<section
				className="overFlowHide ac-our-mission-quote"
				aria-labelledby="our-mission-quote">
				<div className="ac-our-mission-quote-container">
					<div className="ac-our-mission-quote-text">
						<QuoteIcon className="ac-our-mission-quote-icon"></QuoteIcon>
						<br />
						<div className="ac-our-mission-quote-text-style">
							<p>
								In the next several years, AnnieCannons will continue to reach more survivors, train more world-class software engineers, develop more interesting and purposeful products, and re-shape the way we all view the workplace and the world. We have always been reaching for the stars, and we are just getting started.
							</p>
						</div>
					</div>
					<div className="ac-our-mission-quote-attribution">
						<img src={LauraPhoto} className="marginBottom25 ac-our-mission-quote-laura-photo" alt="Black and white of Laura Hackney, AnnieCannons CEO and Cofounder"></img>
						<div className="ac-our-mission-name-title">
							<h2>Laura Hackney</h2>
							<h4>CEO + Cofounder</h4>
						</div>
					</div>
				</div>
				<Star className="ac-our-mission-quote-star"></Star>
			</section>
			<section className="ac-our-impact-our-stories" style={{ backgroundImage: `url(${WaveOffWhite})` }} id ="ac-our-impact-our-stories">
				<div className="ac-our-impact-our-stories-container">
					<div className="ac-our-stories- disciplines-content">
						<h2 className="ac-our-impact-our-stories-subheading" id="-heading">
							Our stories
						</h2>
						<div className="ac-our-impact-our-stories-carousel">
							<Carousel slides={ourTeamSlides} variant="quote"></Carousel>
						</div>
					</div>
				</div>
			</section>
			 {/* <section className="ac-home-people" aria-labelledby="people-heading">
				<div className="ac-home-people-container">
					<h2 className="ac-home-subheading" id="people-heading">
						How we help 
					</h2>
					<Carousel slides={homeSlides} variant="image"></Carousel>
				</div>
				<span
					className="ac-home-wave"
					style={{
						backgroundImage: `url(${Wave1})`,
						bottom: 0,
					}}></span>
			</section> */}
			{/* <section className="ac-our-mission-learn-more" aria-labelledby="learn-more-heading" style={{ backgroundImage: `url(${WaveOffWhite})` }}>
				<div className="ac-our-mission-learn-more-container">
					<h2 className="ac-our-mission-learn-more-subheading" id="learn-more-heading">
						Learn more about us
					</h2>
					<div className="ac-our-mission-learn-more-content">
						<div>
							<FontAwesomeIcon icon={faUsers} className="ac-our-mission-learn-more-icons" />
							<p className="ac-our-mission-learn-more-body">
								Our team is diverse, brilliant and dedicated to empowering others. 							</p>
							<CustomLink
								to='/about/our-team'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Meet our team"
								internal
								className="ac-our-mission-learn-more-button"></CustomLink>
						</div>
						{/* <div>
							<FontAwesomeIcon icon={faChartBar} className="ac-our-mission-learn-more-icons" />
							<p className="ac-our-mission-learn-more-body">
								We have specific ways of measuring the impact of our organization.
							</p>
							<CustomLink
								to='/our-purpose/our-impact'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="View our Impact"
								internal
								className="ac-our-mission-learn-more-button"></CustomLink>
						</div> 
						<div>
							<FontAwesomeIcon icon={faChartBar} className="ac-our-mission-learn-more-icons" />
							<p className="ac-our-mission-learn-more-body">
								Read more about our work and our students our blog</p>
							<CustomLink
								to='https://blog.anniecannons.org'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="View our Blog"
								internal
								className="ac-our-mission-learn-more-button"></CustomLink>
						</div>
					</div>
				</div>
			</section> */}
			<Footer></Footer>
		</div>
	);
}

export default OurPurpose;