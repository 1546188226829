import React, { useEffect } from 'react';
import './styles.scss';
//Components
import Navigation from '../../components/navigation/navigation';
import Footer from '../../components/footer/footer';
//import Button from '../../components/button/button';
import Carousel from '../../components/carousel/carousel';
import CustomLink from '../../components/custom-link/customLink';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
//import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
//import Typography from '@material-ui/core/Typography';
//Assets
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
import Wave3Flipped from '../../assets/waves/wave-3-white-flipped.svg';
import WaveOffWhite from '../../assets/waves/wave-actions-offwhite.svg';
//import PlaceholderImage from '../../assets/photos/team-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faRocket,
	faHandHoldingHeart
} from '@fortawesome/free-solid-svg-icons';
import TrainingIcon from '../../assets/icons/training.svg';
import EmploymentIcon from '../../assets/icons/employ-our-impact-page.svg';
import IncubatorIcon from '../../assets/icons/incubate-our-impact-page.svg';
import RecruitIcon from '../../assets/icons/recruit-our-impact-page.svg';
import QuotesIcon from '../../assets/graphics/quotes.svg';


const ourTeamSlides = [
	{
		image: QuotesIcon,
		description:
			'Making the decision to attend the AnnieCannons coding boot camp was honestly a last resort for me and one of the best decisions I have ever made. They saw my potential, believed in me, had much patience, and taught me the skills I needed to become a successful software engineer. Which then provided me with the tools and income I needed to move towards a more safe, healthy, and stable life. For that I am forever grateful.',
		author: "Da'Kirah",
		age: '27',
	},
	{
		image: QuotesIcon,
		description:
			'I remember [when I] qualified to move forward in the admissions process. I could not believe it. It has [now] been about a year since I [became] a part of the AnnieCannons team. AnnieCannons gives us every reason to keep going, keep learning, and be our own boss by providing us with lifetime trainings [to] stay up-to-date with [the] skills required to be the BEST qualified developers we can be!',
		author: 'LethaLE',
		age: '24',
	},
	{
		image: QuotesIcon,
		description:
			'When I first came to AnnieCannons, I [doubted] myself and felt that I had no purpose at all. As a survivor of human trafficking, I thought that my life was over. AnnieCannons changed my life 180 degrees. I found new meaning and purpose [to] my existence, and never had I imagined that I could be that badass engineer that I always wanted to be.',
		author: 'Supergirl',
	},
	{
		image: QuotesIcon,
		description:
			"When I think about AnnieCannons, the first thing that comes to mind is 'true freedom'. I'm free to exist, think, and speak. I'm free to learn. Free to excel. Free to do whatever I want for the rest of my life.",
		author: 'Morys',
		age: '33',
	},
];


function OurImpact() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="ac-our-impact">
			<Navigation selected='our purpose'></Navigation>
			<section className="ac-our-impact-hero" aria-labelledby="hero-heading">
				<div className="ac-our-impact-hero-container">
					<h1 className="ac-our-impact-heading" id="hero-heading">
						Our Impact
					</h1>
					<p className="desktopWidth50Percent ac-our-impact-body">
					We aim to build generational resilience to exploitation that is driven by improved access to technology and education, and enhanced knowledge of, exposure to, and pathways into workforce opportunities that break the cycles of poverty and vulnerability.</p>
				</div>
				<Star className="ac-our-impact-hero-star"></Star>
				<Star className="ac-our-impact-hero-star"></Star>
				<Star className="ac-our-impact-hero-star"></Star>
				<Star className="ac-our-impact-hero-star"></Star>
			</section>
			<section className="positionRelative ac-our-impact-success">
				<div className="ac-our-impact-success-container">
					<h2 className="ac-our-impact-success-subheading" id="-heading">
						Our success
						</h2>
					<div className="ac-our-impact-success-content">
						<div>
							<h1 className="ac-our-impact-stat">91%</h1>
							<p className="ac-our-impact-stat-text">of learners successfully mastered digital literacy, which is the foundation for 21st century jobs and future coding careers.</p>
						</div>
						<div>
							<h1 className="ac-our-impact-stat">44</h1>
							<p className="ac-our-impact-stat-text">Survivors turned software professionals</p>
						</div>
						<div>
							<h1 className="ac-our-impact-stat">38 </h1>
							<p className="ac-our-impact-stat-text">Mission-aligned funders, clients, and partners who helped link survivors to new careers in tech.</p>
						</div>
					</div>
					<div className="ac-our-impact-success-content" style={{marginTop: '120px'}}>
						<div>
							<h1 className="ac-our-impact-stat">100+</h1>
							<p className="ac-our-impact-stat-text">Client project completed, including website, web apps, and mobile apps.</p>
						</div>
						<div>
							<h1 className="ac-our-impact-stat">4,792</h1>
							<p className="ac-our-impact-stat-text">Total client hours worked by trainees and graduates in 2021</p>
						</div>
						<div>
							<h1 className="ac-our-impact-stat">$1.6 million</h1>
							<p className="ac-our-impact-stat-text"> Dollars earns by graduates in building technology products at AnnieCannons</p>
						</div>
					</div>
				</div>
				<div
					className="ac-our-impact-wave"
					style={{ backgroundImage: `url(${Wave3Flipped})`, bottom: 0 }}>
				</div>
			</section>
			<section className="ac-our-impact-model">
				<div className="ac-our-impact-timeline">
					<div className="ac-our-impact-timeline-container">
						<h2
							className="ac-our-impact-history-subheading"
							id="history-heading">
							Our model
						</h2>
						<div className="ac-our-impact-timeline-content">
							<Timeline>
								<TimelineItem className="ac-our-impact-timeline-item">
									<TimelineContent className="ac-our-history-timeline-left underXsHidden">
										<div className="ac-our-impact-history-timeline-icon">
											<img src={RecruitIcon} alt="Phone icon with individuals chatting." />
										</div>
									</TimelineContent>
									<TimelineSeparator>
										<TimelineDot className="ac-our-impact-history-timeline-dot" />
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent className="ac-our-impact-timeline-info">
										<div className='flexRow alignFlexEnd iconHeader'>
											<h3>Recruit</h3>
											<div className="ac-our-impact-history-timeline-icon overXsHidden">
												<img src={RecruitIcon} alt="Phone icon with individuals chatting." />
											</div>
										</div>
										<p className="ac-our-impact-history-timeline-event">Future engineers join AC as motivated trainees. We get referrals from local service providers that help people who survived trafficking, exploitation and abuse.</p>
									</TimelineContent>
								</TimelineItem>
								<TimelineItem className="ac-our-impact-timeline-item">
									<TimelineContent className="ac-our-history-timeline-left underXsHidden">
										<div className="ac-our-impact-history-timeline-icon">
											<img src={TrainingIcon} alt="Phone icon with individuals chatting." />
										</div>
									</TimelineContent>
									<TimelineSeparator>
										<TimelineDot className="ac-our-impact-history-timeline-dot" />
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent className="ac-our-impact-timeline-info">
										<div className='flexRow alignFlexEnd iconHeader'>
											<h3>Train</h3>
											<div className="ac-our-impact-history-timeline-icon overXsHidden">
												<img src={TrainingIcon} alt="Phone icon with individuals chatting." />
											</div>
										</div>
										<p className="ac-our-impact-history-timeline-event">Our team provides rigorous training in software development. We assess trainees' progress to ensure their skills are top-notch, and build their confidence to become workforce ready.</p>
									</TimelineContent>
								</TimelineItem>
								<TimelineItem className="ac-our-impact-timeline-item">
									<TimelineContent className="ac-our-history-timeline-left underXsHidden">
										<div className="ac-our-impact-history-timeline-icon">
											<img src={EmploymentIcon} alt="Phone icon with individuals chatting." />
										</div>
									</TimelineContent>
									<TimelineSeparator>
										<TimelineDot className="ac-our-impact-history-timeline-dot" />
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent className="ac-our-impact-timeline-info">
										<div className='flexRow alignFlexEnd iconHeader'>
											<h3>Work</h3>
											<div className="ac-our-impact-history-timeline-icon overXsHidden">
												<img src={EmploymentIcon} alt="Phone icon with individuals chatting." />
											</div>
										</div>
										<p className="ac-our-impact-history-timeline-event">Trainees graduate to become developer interns. They develop custom products for clients and build their resumes and portfolios.</p>
									</TimelineContent>
								</TimelineItem>
								<TimelineItem className="ac-our-impact-timeline-item">
									<TimelineContent className="ac-our-history-timeline-left underXsHidden">
										<div className="ac-our-impact-history-timeline-icon">
											<img src={IncubatorIcon} alt="Phone icon with individuals chatting." />
										</div>
									</TimelineContent>
									<TimelineSeparator>
										 <TimelineDot className="ac-our-impact-history-timeline-dot" />
									{/*	<TimelineConnector /> */}
									</TimelineSeparator>
									<TimelineContent className="ac-our-impact-timeline-info">
										<div className='flexRow alignFlexEnd iconHeader'>
											<h3>Launch</h3>
											<div className="ac-our-impact-history-timeline-icon overXsHidden">
												<img src={IncubatorIcon} alt="Phone icon with individuals chatting." />
											</div>
										</div>
										<p className="ac-our-impact-history-timeline-event">We support our graduates in connecting with meaningful career opportunities in the companies and organizations looking for great engineers.</p>
									</TimelineContent>
								</TimelineItem>
								{/* <TimelineItem className="ac-our-impact-timeline-item">
									<TimelineContent className="ac-our-history-timeline-left underXsHidden">
										<div className="ac-our-impact-history-timeline-icon">
											<img src={IncubatorIcon} alt="Phone icon with individuals chatting." />
										</div>
									</TimelineContent>
									<TimelineSeparator>
										<TimelineDot className="ac-our-impact-history-timeline-dot" />
									</TimelineSeparator>
									<TimelineContent className="ac-our-impact-timeline-info last">
										<div className='flexRow alignFlexEnd iconHeader'>
											<h3>Reinforce</h3>
											<div className="ac-our-impact-history-timeline-icon overXsHidden">
												<img src={IncubatorIcon} alt="Phone icon with individuals chatting." />
											</div>
										</div>
										<p className="ac-our-impact-history-timeline-event">Our engineers continue to expand their knowledge, skills and contributions through ongoing training and tech innovation projects.</p>
									</TimelineContent>
								</TimelineItem> */}
							</Timeline>
							<CustomLink
								buttonStyle
								buttonVariant="bare"
								hideButtonPadding
								external={false}
								label={'Read our Impact Report'}
								to={"#"}></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<section className="ac-our-impact-our-stories" style={{ backgroundImage: `url(${WaveOffWhite})` }} id ="ac-our-impact-our-stories">
				<div className="ac-our-impact-our-stories-container">
					<div className="ac-our-stories- disciplines-content">
						<h2 className="ac-our-impact-our-stories-subheading" id="-heading">
							Our stories
						</h2>
						<div className="ac-our-impact-our-stories-carousel">
							<Carousel slides={ourTeamSlides} variant="quote"></Carousel>
						</div>
					</div>
				</div>
			</section>
			<section
				className="ac-our-impact-support-mission"
				aria-labelledby="support-mission-heading">
				<div className="ac-our-impact-support-mission-container">
					<h2 className="ac-our-impact-support-mission-subheading" id="support-mission-heading">
						Support our mission
					</h2>
					<div className="ac-our-impact-support-mission-content">
						<div>
							<FontAwesomeIcon icon={faRocket} className="ac-our-impact-support-mission-icons" />
							<p className="ac-our-impact-support-mission-body">
								Learn the story of our namesake, Annie Cannon, and why she matters.</p>
							<CustomLink
								to='/our-purpose/our-mission'
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Explore our mission"
								internal
								className="ac-our-impact-support-mission-button"></CustomLink>
						</div>
						<div>
							<FontAwesomeIcon icon={faHandHoldingHeart} className="ac-our-impact-support-mission-icons" />
							<p className="ac-our-impact-support-mission-body">
								Learn the story of our namesake, Annie Cannon, and why she matters.</p>
							<CustomLink
								to="/get-involved/become-a-donor"
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Donate"
								internal
								className="ac-our-impact-support-mission-button"></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default OurImpact;