import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import AssessmentImage from '../../../assets/graphics/assessment-icon.png';
import ImpactImage from '../../../assets/graphics/impact-icon.png';
import IntakeImage from '../../../assets/graphics/intake-icon.png';
//import WorkSpaceImage from '../../../assets/graphics/earning-icon.png';
import TrainingImage from '../../../assets/graphics/training-icon.png';
import BusinessImage from '../../../assets/graphics/services-icon.png';
import JoinImage from '../../../assets/graphics/join-icon.png';

class Sidebar extends React.Component {

    render() {
        return (
            <div className="sidebar">
                <Link to="#intake" className="sidebarListItem" style={{ textDecoration: "none" }}>
                    <p>Admissions</p>
                    <img src={IntakeImage} alt="acIcon" />
                </Link>

                <Link to="#train" className="sidebarListItem" style={{ textDecoration: "none" }}>
                    <p>Training</p>
                    <img src={TrainingImage} id="trainingImg" alt="acIcon" />
                </Link>

                <Link to="#earning" className="sidebarListItem" style={{ textDecoration: "none" }}>
                    <p>Job Readiness</p>
                    <img src={ImpactImage} id="workspaceImg" alt="acIcon" />
                </Link>

                <Link to="/#impact" className="sidebarListItem" style={{ textDecoration: "none" }}>
                    <p>Community Connection</p>
                    <img src={JoinImage} id="impactImg" alt="acIcon" />
                </Link>

                <Link to="/#services" className="sidebarListItem" style={{ textDecoration: "none" }}>
                    <p>Hiring</p>
                    <img src={BusinessImage} id="servicesImg" alt="acIcon" />
                </Link>

                <Link to="#assessment" className="sidebarListItem" style={{ textDecoration: "none" }}>
                    <p>Support Us</p>
                    <img src={AssessmentImage} id="assessmentImg" alt="acIcon" />
                </Link>

                {/* <Link to="/#join" className="sidebarListItem" style={{ textDecoration: "none" }}>
                    <p>Join</p>
                    <img src={JoinImage} id="joinImg" alt="acIcon" />
                </Link> */}
            </div>
        )
    }
}

export default Sidebar;