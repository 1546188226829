/**
 * Convert a phrase to dash-case
 *
 * @param  {String} str          String to convert
 * @param  {Boolean} lowercase   Determine if value should be lowercased
 *
 * @return {String}           dash-case version of the str
 */
export const dashCase = (string, delimiter = /[\s\-_]+/) => {
	return string.split(delimiter).join('-').toLowerCase();
};
