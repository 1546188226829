import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
//Components
import Navigation from '../../components/navigation/navigation';
import Footer from '../../components/footer/footer';
//import Button from '../../components/button/button';
import PressCard from '../../components/press-card/pressCard';
import CustomLink from '../../components/custom-link/customLink';
//Assets
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
import Wired from '../../assets/photos/Wired.png';
import Georgetown from '../../assets/photos/georgetown.jpeg';
import StanfordMagazine from '../../assets/photos/stanfordmagazine.png';
import KQED from '../../assets/photos/kqed.png';
import Philanthropy from '../../assets/photos/philanthropy.png';
import MITManagement from '../../assets/photos/MITmanagement.png';
import TheWorld from '../../assets/photos/theworld.png';
import HuffPo from '../../assets/photos/huffpost_JC.png';
import ILO from '../../assets/photos/ilo.png';
import Reuters from '../../assets/photos/reuters.png';
import TheHill from '../../assets/photos/thehill.webp';
import SSIR from '../../assets/photos/ssri.jpeg';
import TheImprint from '../../assets/photos/theimprint.png';
import Switch from '../../assets/photos/switch.png';
import HTLC from '../../assets/photos/HTLC.webp';
import BDE from '../../assets/photos/bde.jpeg';
import Angular from '../../assets/photos/angular.webp';
import Am4Teens from '../../assets/photos/am4teens.png';
import Homeroom from '../../assets/photos/homeroom.jpeg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartBar,
	faHandsHelping,
} from '@fortawesome/free-solid-svg-icons';


function ACPress() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="ac-our-team">
			<Navigation selected='our purpose'></Navigation>
			<section className="overFlowHide ac-our-team-hero" aria-labelledby="hero-heading">
				<div className="ac-our-team-hero-container">
					<h1 className="ac-our-team-heading" id="hero-heading">
						AC in the Press
					</h1>
					<p className="desktopWidth45Percent ac-our-team-body">
						Our programs, talented team members, and partners are featured below.
					</p>
				</div>
				<Star className="ac-our-team-hero-star"></Star>
				<Star className="ac-our-team-hero-star"></Star>
				<Star className="ac-our-team-hero-star"></Star>
			</section>

			<section
				className="ac-our-team-section"
				aria-labelledby="staff-heading">
				<div className="ac-our-team-staff-container">
					<h2 className="ac-our-team-teaching-subheading" id="staff-heading">
						Read More
					</h2>
					<div className="ac-our-team-staff-content">
                    <PressCard
							imgSrc={Wired}
							firstColor='red'
							label='Wired'
							name='Wired'
							link={{ label: '', url: 'https://www.wired.com/story/coders-human-trafficking-survivors-rewrite-identities/' }}
							 />
						<PressCard
							imgSrc={Georgetown}
							firstColor='teal'
							label='Georgetown University'
							name='Georgetown University'
							link={{ label: '', url: 'https://static1.squarespace.com/static/5f4fbcc039526e668a4a0515/t/616708e95d064068bb558173/1634142456513/Jobs+for+All-+Employment+Social+Enterprise+and+Economic+Mobility+in+the+United+States-compressed.pdf' }}
						/>
						<PressCard
							imgSrc={SSIR}
							firstColor='red'
							label="Stanford Social Innovation Review"
							name="Stanford Social Innovation Review"
							link={{ label: '', url: 'https://ssir.org/articles/entry/how_to_ensure_survivors_of_modern_slavery_stay_free?utm_source=Enews&utm_medium=Email&utm_campaign=SSIR_Now#' }}
							 />
							<PressCard
							imgSrc={Philanthropy}
							firstColor='teal'
							label='Philanthropy Women'
							name='Philanthropy Women'
							link={{ label: '', url: 'https://philanthropywomen.org/feminist-philanthropy/more-than-survivors-developing-the-next-generation-of-tech-workers/' }}
							/>
						
						
						<PressCard
							imgSrc={MITManagement}
							firstColor='red'
							label='MIT'
							name='MIT'
							link={{ label: '', url: 'http://mitsloan.mit.edu/ideas-made-to-matter/these-12-north-american-startups-are-improving-lives-through-technology' }}
							 />
						<PressCard
							imgSrc={TheWorld}
							firstColor='red'
							label='The World'
							name='The World'
							link={{ label: '', url: 'https://theworld.org/stories/2018-08-17/sex-trafficking-survivor-moving-learning-how-code' }}
							 />
							<PressCard
							imgSrc={StanfordMagazine}
							firstColor='red'
							label='Stanford Magazine'
							name='Stanford Magazine'
							link={{ label: '', url: 'https://medium.com/stanford-magazine/human-trafficking-survivors-learn-to-code-stanford-8c76890b8f4e' }}
							 />
					
						
						 <PressCard
							imgSrc={KQED}
							firstColor='red'
							label='KQED'
							name='KQED'
							link={{ label: '', url: 'https://www.kqed.org/forum/2010101861110/ellen-ullman-reflects-on-her-life-in-code-and-the-early-days-of-silicon-valley' }}
							 />
						 <PressCard
							imgSrc={HuffPo}
							firstColor='red'
							label='Huffington Post'
							name='Huffington Post'
							link={{ label: '', url: 'https://www.huffpost.com/entry/a-survivor-shares-her-per_b_10107640' }}
							 />
                              <PressCard
							imgSrc={ILO}
							firstColor='red'
							label='International Labor Organization'
							name='International Labor Organization'
							link={{ label: '', url: 'https://www.ilo.org/wcmsp5/groups/public/---ed_emp/---ifp_skills/documents/publication/wcms_762709.pdf' }}
							 />
						<PressCard
							imgSrc={Reuters}
							firstColor='red'
							label='Reuters'
							name='Reuters'
							link={{ label: '', url: 'https://www.reuters.com/article/us-usa-trafficking-code8-7/survivors-draw-on-personal-pasts-to-design-anti-trafficking-software-idUSKCN1QA03R' }}
							 />
                        <PressCard
							imgSrc={TheHill}
							firstColor='red'
							label='The Hill'
							name='The Hill'
							link={{ label: '', url: 'https://thehill.com/opinion/finance/580682-a-solution-to-the-us-worker-shortage-and-inequity-is-hiding-in-plain-sight/' }}
							 />
							<PressCard
							imgSrc={TheImprint}
							firstColor='red'
							label='The Imprint'
							name='The Imprint'
							link={{ label: '', url: 'https://imprintnews.org/news-2/bay-area-training-program-helps-survivors-of-sex-trafficking-forge-careers-in-tech/27879' }}
							/> 
					<PressCard
							imgSrc={Switch}
							firstColor='teal'
							label='Switch'
							name='Switch'
							link={{ label: '', url: 'https://switchthefuture.com/2018/08/21/the-female-founded-startups-at-y-combinator-demo-day-1/?utm_term=0_8e8e6a075d-86aaa896b6-310490809' }}
							 />
					{/* </div>
					<h2 className="ac-our-team-leadership-subheading ac-our-team-staff-separator" id="staff-heading">
						Leadership
							</h2>
					<div className="ac-our-team-staff-content"> */}
						
					</div>
                    <h2 className="ac-our-team-teaching-subheading" id="staff-heading">
						Listen
					</h2>
					<div className="ac-our-team-staff-content">
						<PressCard
							imgSrc={Am4Teens}
							firstColor='teal'
							label='Am4Teens'
							name='Am4Teens'
							link={{ label: '', url: 'https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy82MDFlN2I2MC9wb2RjYXN0L3Jzcw/episode/ZmE5MThhMmEtNjQwNS00ZmZjLWFkYjItMzhkZjVmNjkxMGZi?sa=X&ved=0CAUQkfYCahcKEwig35Xt5eP0AhUAAAAAHQAAAAAQAQ' }}
							 />
						<PressCard
							imgSrc={BDE}
							firstColor='red'
							label="Best Decision Ever"
							name="Best Decision Ever"
							link={{ label: '', url: 'https://www.youtube.com/watch?v=5BVNfcDapbE' }}
							 />
							<PressCard
							imgSrc={HTLC}
							firstColor='teal'
							label='Human Trafficking Legal Center'
							name='Human Trafficking Legal Center'
							link={{ label: '', url: 'https://www.youtube.com/watch?v=rHmdB1ejRfQ' }}
							 />
						{<PressCard
							imgSrc={Homeroom}
							firstColor='teal'
							label='Homeroom'
							name='Homeroom'
							link={{ label: '', url: 'https://podcasts.apple.com/ca/podcast/ep-14-laura-hackney/id1576707434?i=1000537846378' }}
							/> }
						<PressCard
							imgSrc={Angular}
							firstColor='teal'
							label='Angular Experience'
							name='Angular Experience'
							link={{ label: '', url: 'https://open.spotify.com/episode/3v2Doz6s5eL39gde9YdsKC' }}
							 />
						
							
						
					
					{/* </div>
					<h2 className="ac-our-team-leadership-subheading ac-our-team-staff-separator" id="staff-heading">
						Leadership
							</h2>
					<div className="ac-our-team-staff-content"> */}
						
					</div>
				</div>
			</section>

			<section
				className="ac-our-team-multiply"
				aria-labelledby="multiply-heading">
				<div className="ac-our-team-multiply-container">
					<h2 className="ac-our-team-multiply-subheading" id="multiply-heading">
						We multiply our power
					</h2>
					<div className="ac-our-team-multiply-content">
						<div>
							<FontAwesomeIcon icon={faChartBar} className="ac-our-team-multiply-icons" />
							<p className="ac-our-team-multiply-body">
								We help survivors to succeed in various ways.
							</p>
							<br />
							<CustomLink
								to='/our-purpose/our-mission'
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="View our mission"
								internal
								className="ac-our-team-multiply-button"></CustomLink>
						</div>
						<div>
							<FontAwesomeIcon icon={faHandsHelping} className="ac-our-team-multiply-icons" />
							<p className="ac-our-team-multiply-body">
								We invite you to donate to support our mission!
							</p>
							<CustomLink
								to='/get-involved/become-a-donor'
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Donate Today"
								internal
								className="ac-our-team-multiply-button"></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default ACPress;