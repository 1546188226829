import React from 'react';
//import React, { useEffect } from 'react';
import acLogo from '../../../assets/graphics/1C_WHITE_HEX_AnnieCannons_Logo.png';
// import ReactModal from 'react-modal';
//import CustomLink from '../../../components/custom-link/customLink.js';
//import GT from '../../../assets/graphics/AnnieCannonsGT.jpeg';

// const customStyles = {
//     content: {
//       top: '50%',
//       left: '50%',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-50%',
//       transform: 'translate(-50%, -50%)',
//       display: 'flex',
//       justifyContent: 'center',
//       flexDirection: 'column',
//       backgroundColor: '#c09eb4'
//     },
//     image: {
//         width: window.innerWidth > 768 ? '500px' : '300px',
//         marginBottom: '20px'
//     }
//   };

function HeroSection () {
    // const [modalIsOpen, setIsOpen] = React.useState(false);
  
    // function openModal() {
    //   setIsOpen(true);
    // }

	// useEffect(() => {
	// 	setTimeout(openModal, 3000)
	// }, []);
  
    // function closeModal() {
    //   setIsOpen(false);
    // }
  
        return (
            <section className="journeySectionCentered">
                <div className="sectionContentCentered">
                    <img src={acLogo} className="acLogo" alt="AnnieCannons Logo" />
                    <h1 className='h1'>AnnieCannons</h1>
                    <p className="missionStatement" >We train, prepare, and connect individuals who have experienced human trafficking to sustainable careers in tech.</p>
                    <p className="journeyText" style={{ fontSize: `1.2em` }}>Scroll to Start</p>
                    <div className="scrollDowns">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                    </div>
                </div>
                {/* <ReactModal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="AnnieCannons Giving Tuesday"
                    ariaHideApp={false}
                    style={customStyles}
                    >
                       <img src={GT} alt='Donate to AnnieCannons' style={customStyles.image}/>
                       <CustomLink
						    to='/get-involved/become-a-donor'
						    buttonStyle
						    buttonVariant="primary"
						    label="Donate">    
                        </CustomLink>
                </ReactModal> */}
            </section>
        )
}

export default HeroSection;