import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
//import Carousel from '../../components/carousel/carousel';
import Input from '../../components/input/input';
import Textarea from '../../components/textarea/textArea';
import Button from '../../components/button/button';
import Checkbox from '../../components/checkbox/checkbox';
import Navigation from '../../components/navigation/navigation';
// Assets
import Wave1 from '../../assets/waves/wave-1-white.svg';
import Wave3White from '../../assets/waves/wave-3-white.svg';
//import Mission2 from '../../assets/photos/mission-2.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactComponent as Star} from '../../assets/graphics/AC-logo-asterisk.svg';
import {
	faHandHoldingHeart,
    faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import ServiceNow from '../../assets/logos/corporate partner/Servicenow.svg';
const encode = data => {
	return Object.keys(data)
	  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
	  .join("&");
  };

function Volunteer() {
	const [userObject, setUserObject] = React.useState({});
	const [submitted, setSubmitted] = React.useState(false);


	const handleChange = e => {
		var userNewObject = userObject;
		userNewObject[e.target.name] = e.target.value
		setUserObject(userNewObject);
		console.log(submitted)
	}


	const handleSubmit = e => {
		console.log(userObject)
		fetch("/", {
		  method: "POST",
		  headers: { "Content-Type": "application/x-www-form-urlencoded" },
		  body: encode({ "form-name": "volunteer", ...userObject })
		})
			.then((response) => {
			setSubmitted(true)
			setUserObject({})
			})
		  .catch(error => alert(error));
	
		e.preventDefault();
	  };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ac-volunteer">
			<Navigation selected='get involved'></Navigation>
			<section className="ac-volunteer-hero" aria-labelledby="hero-heading">
				<div className="ac-volunteer-hero-container">
					<h1 className="ac-volunteer-heading" id="hero-heading">
                        Become a volunteer
					</h1>
					<p className="desktopWidth45Percent ac-volunteer-body">
                        Empower the emerging workfroce who are resilient beyond measure to thrive.
					</p>
				</div>
				<Star className="ac-volunteer-hero-star"></Star>
				<Star className="ac-volunteer-hero-star"></Star>
			</section>
			<section
				className="ac-volunteer-engineers"
				aria-labelledby="engineers-heading">
				<div className="ac-volunteer-engineers-container">
					 <div className="ac-volunteer-mission-content">
						<h2 className="ac-volunteer-subheading" id="engineers-heading">
							Our volunteers
						</h2>
						<p className="ac-volunteer-body">
							Volunteers help AnnieCannons with wellness workshops, technical consultations, curriculum updates, resume review, corporate sponsorships, fundraising, and much more.
						</p>
					</div> 
				</div>
				<div
					className="ac-volunteer-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
                        bottom: '-50px',
                        backgroundPosition: '50%',
					}}></div>
			</section>
			<section
				className="ac-volunteer-become-partner"
				aria-labelledby="become-partner-heading">
				<div className="ac-volunteer-become-partner-container">
				{submitted ? (
						<div className="ac-volunteer-become-partner-form">
						<h2
						className="ac-volunteer-subheading"
						id="become-partner-heading">
						Thank you for submitting!
						</h2>
						</div>
					) : (
					<form className="ac-volunteer-become-partner-form" onSubmit={handleSubmit}>
						<h2
							className="ac-volunteer-subheading"
							id="become-partner-heading">
							Become a volunteer
						</h2>
						{/* <p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod.
						</p> */}
						<div className="ac-volunteer-become-partner-form-container">
							<div className="ac-volunteer-become-partner-form-column">
								<Input label="First name" name="firstName" onChange={handleChange} required></Input>
								<Input label="Last name" name="lastName" onChange={handleChange} required></Input>
								<Input label="Email" onChange={handleChange} name="userEmail" required></Input>
							</div>
							<div className="ac-volunteer-become-partner-form-column">
								<Textarea label="Your message" required name="userMessage" onChange={handleChange}></Textarea>
								<Checkbox label="I’d like to receive additional information regarding AnnieCannons"></Checkbox>
								<Button label="Submit" variant="primary"></Button>
							</div>
						</div>
					</form>)}
				</div>
			</section>
            <section
				className="ac-become-a-volunteer-partners"
				aria-labelledby="partners-heading">
				<div
					className="ac-become-a-volunteer-partners-top-wave"
					style={{ backgroundImage: `url(${Wave1})`, top: '-136px' }}>
				</div>
				<div className="ac-become-a-volunteer-partners-container">
					<h2 className="ac-become-a-volunteer-subheading" id="partners-heading">
						Our Corporate Partners
					</h2>
					<div className="ac-volunteer-partners-content">
						<img src={ServiceNow} alt="ServiceNow" className="ac-volunteer-partner-logos" />
					</div>
				</div>
			</section>
			<section
				className="ac-volunteer-impact"
				aria-labelledby="impact-heading">
				<div className="ac-volunteer-impact-container">
					<h2 className="ac-volunteer-subheading" id="impact-heading">
						More ways to make an impact
					</h2>
					<div className="ac-volunteer-impact-content">
						<div>
							<FontAwesomeIcon icon={faHandHoldingHeart} />
							<p className="ac-volunteer-body">
								Support our engineering training program through a donation.
							</p>
							<CustomLink
								to="/get-involved/become-a-donor"
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Donate"
								internal></CustomLink>
						</div>
						<div>
							<FontAwesomeIcon icon={faUserFriends} />
							<p className="ac-volunteer-body">
                                Spread the word on our mission by referring a candidate.
							</p>
							<CustomLink
								to="/get-involved/refer-partner"
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Refer a Candidate"
								internal></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default Volunteer;
