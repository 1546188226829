import React from "react";

export class COVID19Banner extends React.Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <div className="covid19Banner">
        <h4>Support AnnieCannons' new programming and donate <a href="https://anniecannons.org/get-involved/become-a-donor" rel="noopener noreferrer" target="_blank">here</a></h4>
      </div>
    )
  }
}