import React from 'react';
import './styles.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {dashCase} from '../../helpers/case/dashCase';

function Textarea(props) {
	const {required, label, placeholder, message, invalid} = props;

	const textareaClass = classNames({
		'ac-textarea': true,
		'is-invalid': invalid,
	});

	return (
		<div className={textareaClass}>
			<label
				className="ac-textarea-label"
				htmlFor={dashCase(`${label}-textarea-field`)}>
				{label}
				{required && <span> *</span>}
			</label>
			<textarea
				className="ac-textarea-field"
				id={dashCase(`${label}-textarea-field`)}
				name={props.name}
				onChange={props.onChange}
				placeholder={placeholder}
				aria-required={required}
				aria-describedby={dashCase(`${label}-message`)}
			/>
			{message && (
				<p
					className="ac-textarea-field-message"
					id={dashCase(`${label}-message`)}>
					{message}
				</p>
			)}
		</div>
	);
}

Textarea.propTypes = {
	// Textarea has an error
	invalid: PropTypes.bool,
	// Textarea label text
	label: PropTypes.string.isRequired,
	// Message beneathe textarea field
	message: PropTypes.string,
	// Textarea placeholder text
	placeholder: PropTypes.string,
	// Textarea field is required to be filled out
	required: PropTypes.bool,
};

Textarea.defaultProps = {
	invalid: false,
	label: '',
	message: '',
	placeholder: '',
	required: false,
};

export default Textarea;
