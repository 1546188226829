import React, { useEffect, useState } from 'react';
import './styles.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//import clsx from 'clsx';
// Components
import Button from '../button/button';
import CustomLink from '../custom-link/customLink';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
//import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';
//import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
//import StarBorder from '@material-ui/icons/StarBorder';
//import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
//import Typography from "@material-ui/core/Typography";
// Assets
import Logo from '../../assets/graphics/ac-logo-black.png';

function Navigation(props) {
	const { selected } = props;

	// State to add a drop shadow to the fixed-position navigation element
	// when the user is scrolling.
	const [isScroll, setIsScroll] = useState(false);
	const [openNav, setOpenNav] = useState(false);
	//const [openMenuItem, setOpenMenuItem] = useState(false);
	const [expanded, setExpanded] = useState("");
	// Determines if user is scrolling
	const handleScroll = () => {
		const scrollPos = window.scrollY;

		if (scrollPos > 0) {
			setIsScroll(true);
		} else {
			setIsScroll(false);
		}
	};

	const handleMenuOpen = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	// Effect to add an event listener for scrolling
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return function cleanup() {
			window.removeEventListener('scroll', handleScroll);
		};
	});

	const navClass = classNames({
		'ac-navigation': true,
		'is-scroll': isScroll,
	});

	const handleDrawerOpen = () => {
		setOpenNav(true);
	};

	const handleDrawerClose = () => {
		setOpenNav(false);
	};

	// const handleClick = () => {
	// 	setOpenMenuItem(!openMenuItem);
	// };

	return (
		<nav className={navClass}>
			<div className="ac-navigation-container">
				<a href="/">
					<div className="ac-navigation-logo" />
				</a>
				<div className="ac-navigation-links">
					<div className="ac-navigation-link-container">
						<Button
							variant="nav"
							label="Our purpose"
							selected={selected === 'our purpose'}></Button>
						<div className="ac-navigation-link-menu">
							<a href="/our-purpose/our-mission" className="ac-navigation-link">
								<span>Our mission</span>
							</a>
							{/* <a href="/our-purpose/our-impact" className="ac-navigation-link">
								<span>Our impact</span>
							</a> */}
						</div>
					</div>
					<div className="ac-navigation-link-container">
						<Button
							variant="nav"
							label="Our work"
							selected={selected === 'our work'}></Button>
						<div className="ac-navigation-link-menu">
							<a href="/our-work/coding-training-program" className="ac-navigation-link">
								<span>Coding training</span>
							</a>
							<a href="/our-work/job-readiness" className="ac-navigation-link">
								<span>Job readiness</span>
							</a>
							<a href="/our-work/community-connection" className="ac-navigation-link">
								<span>Community connection</span>
							</a>
						</div>
					</div>
					<div className="ac-navigation-link-container">
						{/* <Button
							variant="nav"
							label="Hire us"
							selected={selected === 'hire us'}></Button>
						<div className="ac-navigation-link-menu">
							<a href="/hire-us/our-process" className="ac-navigation-link">
								<span>Our process</span>
							</a>
							<a href="/our-services/what-we-build" className="ac-navigation-link">
								<span>What we build</span>
							</a>
							<a href="/our-services/case-studies" className="ac-navigation-link">
								<span>Case studies</span>
							</a>
							<a href="/hire-us/set-up-a-consult" className="ac-navigation-link">
								<span>Set up a consult</span>
							</a>
						</div> */}
						<Button
							variant="nav"
							label="About"
							selected={selected === 'about'}></Button>
						<div className="ac-navigation-link-menu">
							<a href="/about/our-team" className="ac-navigation-link">
								<span>Our team</span>
							</a>
							<a href="/about/our-careers" className="ac-navigation-link">
								<span>Our careers</span>
							</a>
							{/* <a href="/about/our-careers" className="ac-navigation-link">
								<span>Our blog</span>
							</a> */}
							{/* <a href="/our-services/case-studies" className="ac-navigation-link">
								<span>Case studies</span>
							</a> */}
							{/* <a href="/hire-us/set-up-a-consult" className="ac-navigation-link">
								<span>Set up a consult</span>
							</a> */}
						</div>
					</div>
					<div className="ac-navigation-link-container">
						<Button
							variant="nav"
							label="Get involved"
							selected={selected === 'get involved'}></Button>
						<div className="ac-navigation-link-menu">
							<a href="/admissions" className="ac-navigation-link">
								<span>Become a student</span>
							</a>
							<a href="/get-involved/become-a-hiring-partner" className="ac-navigation-link">
								<span>Become a hiring partner</span>
							</a>
							<a href="/get-involved/become-a-donor" className="ac-navigation-link">
								<span>Become a donor</span>
							</a>
							{/* <a href="/get-involved/become-a-volunteer" className="ac-navigation-link">
								<span>Become a volunteer</span>
							</a> */}
							
							<a
								href="/get-involved/refer-partner"
								className="ac-navigation-link">
								<span>Become a referral partner</span>
							</a>
						</div>
					</div>
					<a href="https://www.justgiving.com/campaign/stellarcircle" target="_blank" rel="noopener noreferrer" ><div className="ac-navigation-link-container">
						<Button
							variant="nav"
							label="Stellar Circle"
							></Button>
					</div></a>
				</div>
				<div className="ac-navigation-ctas">
					<CustomLink
						to='/get-involved/become-a-donor'
						buttonStyle
						// buttonvariant="nav"
						//noIcon
						buttonVariant="primary"
						label="Donate"></CustomLink>
					{/* <CustomLink
						to='/hire-us/set-up-a-consult'
						buttonStyle
						buttonVariant="primary"
						label="Set up a consult"></CustomLink> */}
				</div>
			</div>
			<div className="mobile-menu">
				<AppBar className={"app-bar"}>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Drawer
					className={"drawer"}
					variant="persistent"
					anchor="left"
					open={openNav}
				>
					<div className={"drawer-header"}>
						<IconButton onClick={handleDrawerClose}>
							<MenuIcon />
						</IconButton>
					</div>
					<Divider />
					<Link to='/'>
						<img
							src={Logo}
							alt="AC Logo"
							className="menu-logo" />
					</Link>
					<div>
						<Accordion
							square
							expanded={expanded === "panel1"}
							onChange={handleMenuOpen("panel1")}
						// className={"ac-navigation-link-container"}
						>
							<AccordionSummary
								aria-controls="panel1d-content"
								id="panel1d-header"
								expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}>
								<ListItem button className={"ac-navigation-link"}>
									<ListItemText primary="Our purpose" />
								</ListItem>
							</AccordionSummary>
							<AccordionDetails>
								<List component="div" disablePadding className={"ac-navigation-link-container"}>
									<Link to='/our-purpose/our-mission'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Our mission" />
										</ListItem>
									</Link>
									{/* <Link to='/our-purpose/our-team'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Our team" />
										</ListItem>
									</Link>
									<Link to='/our-purpose/our-impact'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Our impact" />
										</ListItem>
									</Link> */}
								</List>
							</AccordionDetails>
						</Accordion>
						<Divider />
						<Accordion
							square
							expanded={expanded === "panel2"}
							onChange={handleMenuOpen("panel2")}
						// className={"ac-navigation-link-container"}
						>
							<AccordionSummary
								aria-controls="panel2d-content"
								id="panel2d-header"
								expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}>
								<ListItem button className={"ac-navigation-link"}>
									<ListItemText primary="Our work" />
								</ListItem>
							</AccordionSummary>
							<AccordionDetails>
								<List component="div" disablePadding className={"ac-navigation-link-container"}>
									<Link to='/our-work/coding-training-program'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Coding training" />
										</ListItem>
									</Link>
									<Link to='/our-work/job-readiness'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Job readiness" />
										</ListItem>
									</Link>
									<Link to='/our-work/community-connection'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Community connection" />
										</ListItem>
									</Link>
								</List>
							</AccordionDetails>
						</Accordion>
						<Divider />
						<Accordion
							square
							expanded={expanded === "panel3"}
							onChange={handleMenuOpen("panel3")}
						// className={"ac-navigation-link-container"}
						>
							<AccordionSummary
								aria-controls="panel3d-content"
								id="panel3d-header"
								expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}>
								<ListItem button className={"ac-navigation-link"}>
									<ListItemText primary="About" />
								</ListItem>
							</AccordionSummary>
							<AccordionDetails>
								<List component="div" disablePadding className={"ac-navigation-link-container"}>
									<Link to='/about/our-team'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Our team" />
										</ListItem>
									</Link>

									<Link to='/about/our-careers'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Our careers" />
										</ListItem>
									</Link>
								</List>
							</AccordionDetails>
						</Accordion>
						<Divider />
						<Accordion
							square
							expanded={expanded === "panel4"}
							onChange={handleMenuOpen("panel4")}
						// className={"ac-navigation-link-container"}
						>
							<AccordionSummary
								aria-controls="panel4d-content"
								id="panel4d-header"
								expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}>
								<ListItem button className={"ac-navigation-link"}>
									<ListItemText primary="Get Involved" />
								</ListItem>
							</AccordionSummary>
							<AccordionDetails>
								<List component="div" disablePadding className={"ac-navigation-link-container"}>
									<Link to='/admissions'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Become a student" />
										</ListItem>
									</Link>
									<Link to='/get-involved/become-a-hiring-partner'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Become a hiring partner" />
										</ListItem>
									</Link>
									<Link to='/get-involved/become-a-donor'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Become a donor" />
										</ListItem>
									</Link>
									{/* <Link to='/get-involved/become-a-volunteer'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Become a volunteer" />
										</ListItem>
									</Link> */}
									<Link to='/get-involved/refer-partner'>
										<ListItem button className={"ac-navigation-link"}>
											<ListItemText primary="Become a referral partner" />
										</ListItem>
									</Link>
								</List>
							</AccordionDetails>
						</Accordion>
						<Divider />
						<Accordion
							square
							style={{ marginBottom: '50px'}}
						// className={"ac-navigation-link-container"}
						>
							<AccordionSummary
								aria-controls="panel4d-content"
								id="panel4d-header">
								<a href="https://www.justgiving.com/campaign/stellarcircle" target="_blank"  rel="noreferrer" style={{ color: 'black', textDecoration: 'none' }}><ListItem button className={"ac-navigation-link"}>
									<ListItemText primary="Stellar Circle" />
								</ListItem></a>
							</AccordionSummary>
						</Accordion>
						<Divider />
					</div>
					<div className={"mobile-nav-buttons"}>
						<CustomLink
							to='/get-involved/become-a-donor'
							buttonStyle
							buttonvariant="nav"
							noIcon
							label="Donate"></CustomLink>
						{/* <CustomLink
							to='/hire-us/set-up-a-consult'
							buttonStyle
							buttonVariant="primary"
							label="Set up a consult"></CustomLink> */}
					</div>
				</Drawer>
			</div>
		</nav>
	);
}


export default Navigation;
