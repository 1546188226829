import React from 'react';
import './styles.scss';
import {NavLink} from 'react-router-dom';
// Assets
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faLinkedin,
	faFacebookSquare,
	faTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import Mailchimp from 'react-mailchimp-form'


function Footer() {
	return (
		<div className="ac-footer" role="contentinfo">
			<nav
				className="ac-footer-navigation -top"
				aria-label="footer main navigation">
				<div className="ac-footer-column">
					<h3>Our purpose</h3>
					<ul>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/our-purpose/our-mission">
								Our mission
							</NavLink>
						</li>
						{/* <li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/our-purpose/our-impact">
								Our impact
							</NavLink>
						</li> */}
					</ul>
				</div>
				<div className="ac-footer-column">
					<h3>Our work</h3>
					<ul>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/our-work/coding-training-program">
								Coding training
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/our-work/job-readiness">
								Job readiness
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to='/our-work/community-connection'>
								Community connection
							</NavLink>
						</li>
					</ul>
				</div>
				<div className="ac-footer-column">
					{/* <h3>Hire us</h3>
					<ul>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/hire-us/our-process">
								Our process
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/our-services/what-we-build">
								What we build
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/our-services/case-studies">
								Case studies
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/hire-us/set-up-a-consult">
								Set up a consult
							</NavLink>
						</li>
					</ul> */}
				
					<h3>About</h3>
					<ul>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/about/our-team">
								Our team
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/about/our-careers">
								Our careers
							</NavLink>
						</li>
						{/* <li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="https://blog.anniecannons.org">
								Our blog
							</NavLink>
						</li> */}
						{/* <li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/about/our-portfolio">
								Our portfolio
							</NavLink>
						</li> */}
					</ul>
				</div>
				<div className="ac-footer-column">
					<h3>Get involved</h3>
					<ul>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/admissions">
								Become a student
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/get-involved/become-a-hiring-partner">
								Become a hiring partner
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/get-involved/become-a-donor">
								Become a donor
							</NavLink>
						</li>
						{/* <li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/get-involved/become-a-volunteer">
								Become a volunteer
							</NavLink>
						</li> */}
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/get-involved/refer-partner">
								Become a referral partner
							</NavLink>
						</li>
						<li className="ac-footer-item">
							<a className="ac-footer-item-link" href="https://www.justgiving.com/campaign/stellarcircle" target="_blank" rel="noreferrer">
								Become a Stellar Circle member
							</a>
						</li>
					</ul>
				</div>
			</nav>
			<hr />
			<div className="ac-footer-navigation -bottom">
				<nav aria-label="footer secondary navigation">
					<ul>
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/get-involved/contact-us">
								Contact us
							</NavLink>
						</li>						
						{/* <li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/">
								Blog
							</NavLink>
						</li> */}
						<li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to="/our-work/ac-in-the-press">
								AC in the Press
							</NavLink>
						</li>
						{/* <li className="ac-footer-item">
							<NavLink className="ac-footer-item-link" to='/our-purpose/careers'>
								Careers
							</NavLink>
						</li> */}
					</ul>
				</nav>
				<nav aria-label="footer secondary navigation">
					<ul>
						<p>Join our newsletter!</p>
						<Mailchimp
							className="ac-newsletter ac-input-newsletter"
							action='https://anniecannons.us11.list-manage.com/subscribe/post?u=4e3483cf06142ab11fde0f9b4&amp;id=698b1dbe03&amp;f_id=00e59be0f0'
							fields={[
							{
								name: 'EMAIL',
								placeholder: 'Email',
								type: 'email',
								required: true
							}
							]}
						/>
					</ul>
				</nav>
				<nav className="ac-footer-social" aria-label="social media links">
					<ul>
						<li className="ac-footer-item">
							<a
								href="https://www.facebook.com/AnnieCannons/"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Facebook">
								<FontAwesomeIcon icon={faFacebookSquare} aria-hidden="true" />
							</a>
						</li>
						<li className="ac-footer-item">
							<a
								href="https://twitter.com/anniecannons?lang=en"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Twitter">
								<FontAwesomeIcon icon={faTwitter} aria-hidden="true" />
							</a>
						</li>
						<li className="ac-footer-item">
							<a
								href="https://www.youtube.com/channel/UC2hRqe-RiV4ququltgpw5pg"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Youtube">
								<FontAwesomeIcon icon={faYoutube} aria-hidden="true" />
							</a>
						</li>
						<li className="ac-footer-item">
							<a
								href="https://www.linkedin.com/company/anniecannons/"
								target="_blank"
								rel="noopener noreferrer"
								aria-label="LinkedIn">
								<FontAwesomeIcon icon={faLinkedin} aria-hidden="true" />
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
}

export default Footer;
