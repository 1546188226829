import React, { useEffect } from 'react';
import './styles.scss';
import './styles.css';
// Components
//import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
// Assets
// import OurMission from '../../assets/photos/home-1.png';
// import OurServices from '../../assets/photos/home-2.jpg';
// import Wave1 from '../../assets/waves/wave-1-white.svg';
// import Wave2 from '../../assets/waves/wave-2-white.svg';

//import CollegeMapper from '../../assets/logos/client/CollegeMapper.svg';
// import {ReactComponent as HeroGraphic} from '../../assets/graphics/line art.svg';
// import {ReactComponent as Star} from '../../assets/graphics/AC-logo-asterisk.svg';
// import {ReactComponent as ArrowDown} from '../../assets/icons/Arrow - default.svg';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faHandHoldingHeart, faHeadset} from '@fortawesome/free-solid-svg-icons';


import Zoom from 'react-reveal/Zoom';
import { COVID19Banner } from "./homecomponents/COVID19Banner";
import VideoBackground from './homecomponents/VideoBackground';
import Sidebar from './homecomponents/Sidebar';
import HeroSection from './homecomponents/HeroSection1';
import IntakeSection from './homecomponents/IntakeSection2';
import TrainingSection from './homecomponents/TrainingSection3';
import AssessmentSection from './homecomponents/AssessmentSection4';
import WorkspaceSection from './homecomponents/WorkspaceSection5';
import ImpactSection from './homecomponents/ImpactSection7';
import ServicesSection from './homecomponents/ServicesSection9';
//import JoinActionSection from './homecomponents/JoinActionSection10';
//import JoinSection from './homecomponents/JoinSection11';



function Home() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	
	return (
		<div className="ac-home">
			<Navigation></Navigation>
			<COVID19Banner />
			<div className="homeWrapper">
                <VideoBackground />
                <Sidebar />
                <Zoom>
                    <HeroSection />
                </Zoom>
                <Zoom>
                    <IntakeSection />
                </Zoom>
                <Zoom>
                    <TrainingSection id="train" />
                </Zoom>
                <Zoom>
                    <WorkspaceSection id="work" />
                </Zoom>
                <Zoom>
                    <ImpactSection id="impact" />
                </Zoom> 
                <Zoom>
                    <ServicesSection id="services" />
                </Zoom>
				<Zoom>
                    <AssessmentSection id="assessment" />
                </Zoom>
            </div>
			{/* <section className="ac-home-hero" aria-labelledby="hero-heading">
			<video className="fixedBackgroundVideo" muted loop autoPlay>
                    <source src={sectionVideo} type="video/mp4" />
                    <source src={sectionVideo} type="video/ogg" /> Your browser does not support the video tag.
                </video>
				<div className="ac-home-hero-container">
					<h1 className="ac-home-heading" id="hero-heading">
						AnnieCannons’ workforce of engineers embodies the insight and
						ingenuity of <span>survivorship</span>
					</h1>
					<CustomLink
						buttonStyle
						svg={<ArrowDown className="ac-home-arrow-default"></ArrowDown>}
						ariaConfig={{'aria-label': 'Skip to content'}}
						to="#our-mission-and-services"
						hash></CustomLink>
				</div>
				<div
					className="ac-home-wave"
					style={{backgroundImage: `url(${Wave1})`, bottom: '-1px'}}></div>
			</section> */}
			{/* <section
				className="ac-home-mission-and-services"
				id="our-mission-and-services"
				aria-labelledby="our-mission-heading our-services-heading">
				<div className="ac-home-mission-and-services-container">
					<div className="ac-home-mission-content">
						<h2 className="ac-home-subheading" id="our-mission-heading">
							Our mission
						</h2>
						<p className="ac-home-body">
							Our mission to <span>transform survivors of trafficking and gender-based violence into software engineers</span> creates the pathways survivors need to gain in-demand skills, and build economic security to live a lifetime free of exploitation.
						</p>
						<CustomLink
							to='/our-purpose/our-mission'
							buttonStyle
							buttonVariant="secondary"
							label="Learn about our mission"
							internal></CustomLink>
					</div>
					<HeroGraphic
						className="ac-home-hero-image"
						aria-hidden="true"></HeroGraphic>
					<Star className="ac-home-star-image -sm" aria-hidden="true"></Star>
					<Star className="ac-home-star-image -md" aria-hidden="true"></Star>
					<Star className="ac-home-star-image -lg" aria-hidden="true"></Star>
					<img src={OurMission} alt=""></img>
					<div className="ac-home-services-content">
						<h2 className="ac-home-subheading" id="our-services-heading">
							Our programs
						</h2>
						<p className="ac-home-body">
							Our Coding Training Program and Developer Internship Program empower survivors and help them become economically secure through viable skills and jobs in the digital workforce.
						</p>
						<CustomLink
							buttonStyle
							buttonVariant="secondary"
							label="Explore our programs"
							internal
							to='/our-work/coding-training-program'></CustomLink>
					</div>
					<div className="ac-home-mission-content">
						<h2 className="ac-home-subheading" id="our-mission-heading">
							Our services
						</h2>
						<p className="ac-home-body">
							Our innovative engineers create websites and apps using their
							expertise as survivors to make a broader impact.
						</p>
						<CustomLink
							buttonStyle
							buttonVariant="secondary"
							label="Explore our services"
							internal
							to='/hire-use/our-process'></CustomLink>
					</div>
					<img src={OurServices} alt=""></img>
				</div>
				<div
					className="ac-home-wave"
					style={{
						backgroundImage: `url(${Wave2})`,
						bottom: '-100px',
					}}></div>
			</section> */}
			{/* <section
				className="ac-home-top-funders"
				aria-labelledby="top-funders-heading">
				<div className="ac-home-top-funders-container">
					<h2 className="ac-home-subheading" id="top-funders-heading">
						Our top funders
					</h2>
					<div className="ac-home-top-funders-content">
						<img
							src={Cisco}
							alt="Cisco"
							className="ac-home-top-funders-image"></img>
						<img
							src={Motorola}
							alt="Motorola"
							className="ac-home-top-funders-image"></img>
						<img
							src={Jensen}
							alt="Jensen"
							className="ac-home-top-funders-image"></img>
						<img
							src={McGovern}
							alt="McGovern"
							className="ac-home-top-funders-image"></img>
					</div>
				</div>
			</section> */}
			{/* <section
				className="ac-home-connect"
				aria-labelledby="lets-connect-heading">
				<div className="ac-home-connect-container">
					<h2 className="ac-home-subheading" id="lets-connect-heading">
						Let's connect: Choose a path
					</h2>
					<div className="ac-home-connect-content">
						<div>
							<FontAwesomeIcon icon={faHeadset} />
							<p className="ac-home-body">
								Let’s discuss how our engineers can solve your technology
								problems.
							</p>
							<CustomLink
								to='/hire-us/set-up-a-consult'
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Set up a consult"
								internal></CustomLink>
						</div>
						<div>
							<FontAwesomeIcon icon={faHandHoldingHeart} />
							<p className="ac-home-body">
								Support our engineering training program through a donation.
							</p>
							<CustomLink
								to='/get-involved/become-a-donor'
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Donate"
								internal></CustomLink>
						</div>
					</div>
				</div>
			</section> */}
			<Footer></Footer>
		</div>
	);
}

export default Home;
