import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import assessmentImg from '../../../assets/graphics/assessment-icon.png'

class AssessmentSection extends React.Component {

    render() {
        return (
            <section className="journeySection" style={{ paddingTop: `0px` }} id="assessment">
                <img src={assessmentImg} className="tabletIcon" alt="Assessment Icon" />
                <Container className="sectionContent">
                    <Row className="sectionRow">
                        <Col xs={12} sm={9} md={9}>
                            <div className="mobileIconWrapper">
                                <h2 className="titleText">SUPPORT US</h2>
                                <img src={assessmentImg} className="mobileIcon" alt="Assessment Icon" />
                            </div>
                            <p className="paragraphText">"At Twitter, we meet with nonprofits all over the world, and really we haven't come across an approach that is as innovative as what AnnieCannons has created. Being able to work with AnnieCannons and learn from their experience but also working with survivors who now feel empowered to enable technologies like ours to be used for a good cause really is why we are here and how we want to use our platform. AnnieCannons is really leading the world in this space."</p>
                    <p className="paragraphText montserrat"><span style={{ color: `#e7b721` }}>-Patricia Cartes Andres, Head of Global Trust and Safety Outreach, Public Policy at Twitter.</span></p>
                        </Col>
                        <Col xs={12} sm={3} md={3} className="imageContainer">
                            <img src={assessmentImg} alt="Assessment Icon" />
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default AssessmentSection;





