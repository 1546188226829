import React from 'react';
import CaseStudyTemplate from './caseStudyTemplate';
import heroImg from '../../assets/graphics/Aya1.png';
import clientTextLogo from '../../assets/logos/client/vitala.png';
import capImage from '../../assets/graphics/Aya2.webp';
import capImagePerson from '../../assets/graphics/Aya3.webp';


function VitalaGlobal() {

    return (
        <CaseStudyTemplate
            title='Aya Contigo Web Application'
            subTitle='AnnieCannons partnered with Vitala Global to create an application that accompanies  individuals to safely self-manage an abortion with medication and contraception decision-making tailored to their needs. The current iteration of the app is built specifically for women in Venezuela dealing with a humanitarian crisis but has capabilities to adapt content into various contexts and languages.'
            heroImg={heroImg}
            overviewText='Aya Contigo is an app that accompanies women through a safe medication abortion and family planning journey, providing them with an evidence-based, reliable private digital resource to avoid unsafe abortions.
            '
            clientTextLogo={clientTextLogo}
            logoAltText={'Aya Control'}
            client='Vitala Global'
            industry='Nonprofit'
            projectType='Web Application'
            impactMetricNumber1='+25,000,000'
            impactMetricText1='Number of unsafe abortions per year worldwide'
            impactMetricNumber2='7,000,000'
            impactMetricText2='Hospitalizations and deaths due to unsafe abortions per year worldwide'
            impactMetricNumber3='1000'
            impactMetricText3='Co-creators that helped shape Aya Contigo, with over a dozen Latin American grassroots organizations, in partnership with leading international SRH organizations.'
            impactQuote="We have had an amazing experience with AnnieCannons, working with Grace and Laura and their dedicated team.  Right from the beginning AnnieCannons understood our vision and mission, and they were super adaptable and agile with her needs.  Working with Grace was effortless - easy communication, efficient, and effective.  She was basically an extension of our team!  With our shared core values and mission to empower vulnerable women, we combined synergies to develop a product with double the impact!  We're very grateful to have AnnieCannons as our technology partners and we look forward to many more collaborations in the future. - 
            Genevieve Tam,
            Co-Founder of Vitala Global"
            problem='Access to abortion and reproductive rights can be incredibly difficult depending on where you are in the world. In countries where abortion is restricted or probibited alltogether, individuals who are seeking an abortion risk unintended pregnancies, persecution, prosecution, injury and death from unsafe abortions.'
            problemDifficulty='[Show how the probelm was particulary "tough," "complex," "thorny," "challenging," etc.]'
            problemConstraints='[Feel free to highlight any other relevant constraints.]'
            problemImportance='[Convey why this problem or overall issue is important on a human level.]'
            process='AnnieCanons worked closely with the Vitala Global team to bring Aya Contigo to life. The team members at Vitala Global are industry experts of obstetrics and gynecology, public health, and finance but are less familiar with the software development lifecycle and how to create technology tools.'
            processImg1={heroImg}
            processImg2={capImage}
            solution='AnnieCannons approached this collaboration not only as a technology undertaking but an opportunity to help the Vitala Global team meaningfully engage with and understand the process of developing the product. Together we translated the beautiful designs, thoughtful assessments and algorithms, and strategic notification structure into the features and functionality you see in the Aya Contigo app today.
            '
            featuresAndDeliverables={
                [
                    'To ensure users are able to access Aya content even when they are out of range, we’ve configured the app to display content while offline.',
                    'To support users through a self-managed medication abortion process:  optional WhatsApp notifications range from pill reminders, to emotional support, to assessing if their abortion is completed, and supporting post-abortion contraception decision making. ',
                ]
            }
            solutionImg1={capImagePerson}
            solutionImg2={heroImg}
        />
    )
}

export default VitalaGlobal;