import React from 'react';
import CaseStudyTemplate from './caseStudyTemplate';
import heroImg from '../../assets/photos/lisaHealth-heroImg.png';
import clientTextLogo from '../../assets/logos/client/LisaHealth.svg';
import lisaHealth from '../../assets/photos/lisaHealth2.png';
import lisaHealthImg from '../../assets/photos/lisahealthimage.jpeg';



function LisaHealth () {

    return (
        <CaseStudyTemplate
            title='Lisa Health Web Application'
            subTitle='Lisa Health is a web app for midlife women to manage their menopause transition and midlife well-being'
            heroImg={heroImg}
            overviewText='The app brings together science and community in a responsive, fast, and easy-to-use platform that helps women in the menopause take steps to be their best selves every day – and demands an end to the shame and secrecy that forces most women to face this transition alone.'
            clientTextLogo={clientTextLogo}
            logoAltText={'Lisa Health'}
            client='Lisa Health'
            industry='Healthcare'
            projectType='Web Application'
            impactMetricNumber1='20%'
            impactMetricText1='Key metric that illustrates success or human impact'
            impactMetricNumber2='18'
            impactMetricText2='Key metric that illustrates success or human impact'
            impactMetricNumber3='24'
            impactMetricText3='Key metric that illustrates success or human impact'
            impactQuote='"I’ve never had such a high quality software product delivered to me for testing by internal OR external developers in my 31-year career in Health Tech." -Ann Garnier,
            Founder and CEO,
            Lisa Health'
            problem='Founder Ann Garnier came to AnnieCannons with decades of experience managing health tech initiatives at major corporations like Relay Health, Paradigm Health, and Carepayment - and a great idea for a thirsty market of midlife women navigating menopause. No one was offering reliable, accurate information about menopause—which effects 50% of the human population—without making women feel somehow wrong. Ann had spent years compiling scientific evidence of what women could do to improve some of menopause’s hardest symptoms - most of it tailored to the woman’s individual experience. She had technical advisors, even some designs, but no committed development team that could create a delightful user experience on the front-end and a fast, sophisticated personal recommendation calculation on the back end. She had only a bootstrap budget. Then she met us.'
            problemDifficulty=''
            //problemConstraints='[Feel free to highlight any other relevant constraints.]'
            //problemImportance='[Convey why this problem or overall issue is important on a human level.]'
            process='AnnieCannons worked with Ann’s designer and technical advisors to develop a feature-ful minimum viable product to gather critical early user feedback and serve as a basis for Lisa Health’s ongoing fundraising efforts. We chose a progressive web app to balance the need for an always-unique user experience with frictionless user acquisition.'
            processImg1={heroImg}
            processImg2={lisaHealth}
            solution='By partnering with AnnieCannons, Lisa Health saw the opportunity to make a real and tangible difference to a team of women who know what it means to tackle undeserved stigma. Projects like this help to showcase the talents of the survivors of gender-based violence and human trafficking and put an end to the culture of victim shaming they must often endure. '
            featuresAndDeliverables={
                [
                    'Full-stack React web app, leveraging Cognito for user authentication.',
                    'Integration of Typeform via application programming interface for intake assessment.','Automation of platform notifications to increase user engagement and retention as users track symptom improvements.',
                    'Implementation of Mixpanel analytics to support growth planning.',
                    'Integration of content management system to intelligently deliver personalized content.','Mechanism for Lisa Health team to upload fresh content to the app without new code.',
                ]
            }
            solutionImg1={lisaHealthImg}
            solutionImg2={lisaHealthImg}
        />
    )
}

export default LisaHealth;