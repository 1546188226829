import React from 'react';
import Rodal from 'rodal';
import '../rodal.css';
import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import ImpactImg from '../../../assets/graphics/impact-icon.png'


class WorkplaceSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    show() {
        this.setState({ visible: true });
    }

    hide() {
        this.setState({ visible: false });
    }

    render() {
        return (
            <section className="journeySection" id='earning'>
                <img src={ImpactImg} className="tabletIcon" alt="Earning Icon" />
                <Container className="sectionContent">
                    <Row className="sectionRow">
                        <Col xs={12} sm={9} md={9}>
                            <div className="mobileIconWrapper">
                                <h2 className="titleText">JOB READINESS</h2>
                                <img src={ImpactImg} className="mobileIcon" alt="Earning Icon" />
                            </div>
                            <h4 className="missionText">The program incorporates a trauma-informed pedagogy that focuses on building personal skills and confidence in the workplace.</h4>
                            <p className="paragraphText">Attention is given to the creation of a professional portfolio, resume development, and technical interviewing preparation..</p>
                            <p className="paragraphText">Participants will have access to individualized one-on-one coaching from instructors and mentors as well as workshops tailored to the unique learning and support needs of survivors, women, and BIPOC and LGBTQ+ individuals. Participants will have exposure to role models, professional networks, and wellness experts throughout their learning journey.</p>
                        </Col>
                        <Col xs={12} sm={3} md={3} className="imageContainer">
                            <img src={ImpactImg} alt="Earning Icon" />
                        </Col>
                    </Row>
                </Container>
                <div className="homeButtonWrapper">
                    <a href="/our-work/job-readiness"><button className="purpleBtns">See our model</button></a>
                    <a href='/about/our-team#board'>
                        <button className="purpleBtns">Meet our mentors</button>
                    </a>
                </div>

                {/* <div onClick={this.show.bind(this)} className="circleModalButton">
                    <p>Our Values</p>
                </div> */}

                <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} >
                    <div className="circleModalContent">
                        <div>
                            <h4 className="circleModalTitle">Our Values</h4>
                            <p>“They thought they buried us.  They didn’t realize we were seeds.”</p>
                            <p><strong>1:  We will proudly be who we are. </strong><br />Who anyone is, is hers to be.  No shame allowed on yourself or any other.  Never apologize for being who you are, or where you are.</p>
                            <p>2:<strong>We will constantly learn. </strong><br />Learning is an investment in your own future and the future of our community. We will invest ourselves and accept challenges. </p>
                            <p><strong>3:  We will replace injustice through community. </strong><br /> No one shall be asked to pay for her lack of privilege. We will share any privileges we have with our community until they see the same opportunities we have had.</p>
                            <p><strong>4:  We will celebrate our differences. </strong><br /> Different is a good thing.  Our differences make us all better, and make our community stronger.</p>
                            <p><strong>5:  We will honor the families that make us stronger.</strong> <br /> Our children and our families give us purpose and strength.  We will never treat them as a weakness or exclude each other on the basis of family need.</p>
                            <p><strong>6:  We will build a better world by combining all of our intelligence and talent. </strong><br /> Hierarchies do not generate the smartest decisions.</p>
                        </div>
                    </div>
                </Rodal>
            </section>
        )
    }
}

export default WorkplaceSection;