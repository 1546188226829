import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import {dashCase} from '../../helpers/case/dashCase';

function Dropdown(props) {
	const {options, label, required, placeholder, message, invalid} = props;
	const [value, setValue] = useState('');

	const handleChange = (event) => {
		setValue(event.target.value);
	};

	const dropdownClass = classNames({
		'ac-dropdown': true,
		'is-placeholder': !value,
		'is-invalid': invalid,
	});

	return (
		<div className={dropdownClass}>
			<label
				className="ac-dropdown-label"
				htmlFor={dashCase(`${label}-message`)}>
				{label} {required && <span>*</span>}
			</label>
			<select
				className="ac-dropdown-select"
				id={dashCase(`${label}-message`)}
				value={value}
				onChange={handleChange}>
				<option className="ac-dropdown-option" value="" disabled>
					{placeholder}
				</option>
				{options &&
					options.map((item, i) => {
						return (
							<option
								className="ac-dropdown-option"
								key={`${item.label}-${i}`}
								value={item.value}>
								{item.label}
							</option>
						);
					})}
			</select>
			{message && (
				<p className="ac-dropdown-message" id={dashCase(`${label}-message`)}>
					{message}
				</p>
			)}
		</div>
	);
}

Dropdown.propTypes = {
	// Dropdown options
	options: PropTypes.array.isRequired,
	// Dropdown label text
	label: PropTypes.string.isRequired,
};

Dropdown.defaultProps = {
	options: [],
};

export default Dropdown;
