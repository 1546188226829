import React from 'react';
import CaseStudyTemplate from './caseStudyTemplate';
import heroImg from '../../assets/photos/sugarjar1.png';
import clientTextLogo from '../../assets/photos/sugarjar.jpeg';
import capImage from '../../assets/photos/sugarjar2.jpeg';
import capImagePerson from '../../assets/photos/sugarjar4.jpeg';


function SugarJar() {

    return (
        <CaseStudyTemplate
            title='The Sugar Jar Community App'
            subTitle='Our team worked with Yasmine Cheyenne to develop a safe space for users to develop self-awareness, learn boundaries, and find tools and resources to support individuals on their self-healing journey.'
            heroImg={heroImg}
            overviewText='The Sugar Jar® Community App is a mobile application that assists users in creating a safe space to develop self-awareness, learn boundaries, and find tools and resources to support self-healing. Our team built a custom application that integrated various tools for revenue generation, content management, and user engagement.'
            clientTextLogo={clientTextLogo}
            logoAltText={'Sugar Jar Community App'}
            client='Yasmine Cheyenne'
            industry='Wellness'
            projectType='Mobile App'
            // impactMetricNumber1='20%'
            // impactMetricText1='Key metric that illustrates success or human impact'
            // impactMetricNumber2='18'
            // impactMetricText2='Key metric that illustrates success or human impact'
            // impactMetricNumber3='24'
            // impactMetricText3='Key metric that illustrates success or human impact'
            impactQuote='I love this app! The tools and information are helpful and informative. I have been using the app daily since I first downloaded it and it has been doing wonders for my self-care routine and self-healing journey. In addition, it feels really good to have all things self-healing in one spot! I will continue to use this app and recommend it to others. I highly recommend it! - EBONYSC'
            
            problem='Finally, you don’t have to figure out how to begin your self-healing journey alone. The Sugar Jar® Community App, created by Yasmine Cheyenne, is a safe space to develop self-awareness, learn boundaries, and find tools and resources to support you on your self-healing journey.
            When we begin our self-healing journey, we start developing a deeper self-awareness, and we become clearer on who we really are and what we really want. So often we’re not even listening to ourselves, because we’ve been trained to do what our family or our friends or what society says we “should” be doing. But we fit in best when we’re being ourselves.  We belong in the world, when we belong within ourselves.  We can show up for those we love when we’re able to show up for ourselves.  Your power lies in being willing to choose yourself.'
            // problemDifficulty='[Show how the probelm was particulary "tough," "complex," "thorny," "challenging," etc.]'
            // problemConstraints='[Feel free to highlight any other relevant constraints.]'
            // problemImportance='[Convey why this problem or overall issue is important on a human level.]'
            process='AnnieCanons worked closely with Yasmine Cheyenne to create The Sugar Jar® Community App. Yasmine brought existence knowledge and expertise in self healing and exploration in pursuit of personal wellbeing. AnnieCannons approached this collaboration not only as a technology undertaking but as an opportunity to meaningfully engage with Yasmine to help define what we want to build, why we want to build it, who we want to build it for, and how we will bring it to life. This Discovery work was key to establishing the strategy that powers the app experience. Together we translated this work into code providing a beautiful and user-friendly experience where users can access content, express themselves and respond through written and audio notes, follow their progress, and share their experience along the way.
            '
            processImg1={heroImg}
            processImg2={capImage}
            solution='The Sugar Jar® Community App is a mobile app built using React Native - deployed on both iOS and Android and available in the App and Play stores. The application has advanced functionality that allows the user to set topics that narrow down their focus areas, listen to audio content, watch video content, take notes while listening or watching, receive and respond to regular journal prompts with text or audio recordings, track your progress, review or revisit what you’ve done, share your experience with others.
            The Sugar Jar® Community App makes it accessible and easeful to take on your self healing journey with the support of Yasmine Cheyenne. Three key features or deliverables that show a specific solution or highlights a use case or example
            '
            featuresAndDeliverables={
                [
                    'Listen and Watch: Regular content is published and delivered immediately to users in the form of audio and video.',
                    'React, Respond, Reflect: Users can take notes while an audio or video session is playing. In addition, users can respond to daily journal entries by writing text or leaving a voice recording. Notes and journal entries are saved and accessible, allowing users to track their progress as well as reflect on their journey.',
                    'Share: Each piece of app content and the user’s summarized thoughts are shareable via the app, helping to spread the word and encourage others to join The Sugar Jar Community.',
                ]
            }
            solutionImg1={capImagePerson}
            solutionImg2={heroImg}
        />
    )
}

export default SugarJar;