import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';


function PressCard (props) {
	const { imgSrc, label, link } = props;


	return (
        <div>
            <a href={link.url} rel='noopener noreferrer' target='_blank'>
            <div className="ac-team-card" aria-label={label}>
                <button className="cursorPointer ac-press-card-image-btn" >
                    <img src={imgSrc} alt={label}></img>
                </button>
                {/* <div className="ac-team-card-content">
                    <div className="ac-team-card-name">{name}</div>
                </div> */}
            </div>
            </a>
        </div>
	);
}

PressCard.propTypes = {
	// Card image source
	imgSrc: PropTypes.string.isRequired,
	// Which color is fist in the color overlay
	firstColor: PropTypes.string.isRequired,
	// Card label text
	label: PropTypes.string.isRequired,
	// Adds a CustomLink to Card
	link: PropTypes.object,
    // Card name text
	name: PropTypes.string.isRequired,
};

PressCard.defaultProps = {
	imgSrc: '',
	firstColor: 'red',
	label: '',
	link: {},
    name: '',
};

export default PressCard;
