import React, { useEffect } from 'react';
import './styles.scss';
//import { Link } from 'react-router-dom';
//Components
import Navigation from '../../components/navigation/navigation';
import Footer from '../../components/footer/footer';
//import CustomLink from '../../components/custom-link/customLink';

//Assets
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
//import Wave1 from '../../assets/waves/wave-1-white.svg';
import Wave3White from '../../assets/waves/wave-3-white.svg';
import WavePurple from '../../assets/waves/wave-3-purple.svg';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
// 	faHeadset,
// 	faCodeBranch,
// } from '@fortawesome/free-solid-svg-icons';


function CaseStudyTemplate(props) {

	useEffect(() => {
		window.scrollTo(0, 0);
		console.log('props.heroImg', props.heroImg)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	return (
		<div className="ac-caseStudyTemplate">
			<Navigation selected='our services'></Navigation>
			<section className="ac-caseStudyTemplate-hero" aria-labelledby="hero-heading">
				<div className="ac-caseStudyTemplate-hero-container">
					<div className='flexRow alignItemsCentered'>
						<div className='flex1'>
							<h1 className="ac-caseStudyTemplate-title" id="hero-heading">{props.title}</h1>
							<p className="ac-caseStudyTemplate-subTitle">{props.subTitle}</p>
						</div>
						<img src={props.heroImg} className='heroImg' alt='' />
					</div>
				</div>
				<Star className="ac-caseStudyTemplate-hero-star"></Star>
				<Star className="ac-caseStudyTemplate-hero-star"></Star>
				<Star className="ac-caseStudyTemplate-hero-star"></Star>
				<Star className="ac-caseStudyTemplate-hero-star"></Star>
			</section>
			<section
				className="positionRelative ac-caseStudyTemplate-overview">
				<div className="ac-caseStudyTemplate-overview-container">
					<div className="ac-caseStudyTemplate-overview-content">
						<div className='desktopMarginRight60'>
							<h2 className="ac-caseStudyTemplate-subheadings" id="-heading">Project overview</h2>
							<p className="ac-caseStudyTemplate-subtext">{props.overviewText}</p>
						</div>
						<div className='blueDiv'>
							{props.clientTextLogoIsText ?
								<p className='logoText'>{props.clientTextLogo}</p>
								:
								<img src={props.clientTextLogo} className='clientTextLogo' alt={props.logoAltText} />
							}
							<p><span className='semiBold'>Client:</span> {props.client}</p>
							<p><span className='semiBold'>Industry:</span> {props.industry}</p>
							<p><span className='semiBold'>Project Type:</span> {props.projectType}</p>
						</div>
					</div>
					<div className="ac-caseStudyTemplate-impact-content">
						{/* <h2 className="ac-caseStudyTemplate-subheadings textAlignCenter" id="-heading">Our impact</h2>
						<div className='desktop3ColumnRow'>
							<div>
								<h1 className="ac-caseStudyTemplate-stat">{props.impactMetricNumber1}</h1>
								<p className="ac-caseStudyTemplate-stat-text">{props.impactMetricText1}</p>
							</div>
							<div>
								<h1 className="ac-caseStudyTemplate-stat">{props.impactMetricNumber2}</h1>
								<p className="ac-caseStudyTemplate-stat-text">{props.impactMetricText2}</p>
							</div>
							<div>
								<h1 className="ac-caseStudyTemplate-stat">{props.impactMetricNumber3}</h1>
								<p className="ac-caseStudyTemplate-stat-text">{props.impactMetricText3}</p>
							</div>
						</div> */}
						<div className='blueDiv'>
							<p>{props.impactQuote}</p>
						</div>
					</div>
					<Star className="ac-caseStudyTemplate-overview-star"></Star>
					<Star className="ac-caseStudyTemplate-overview-star"></Star>
					<Star className="ac-caseStudyTemplate-overview-star"></Star>
					<Star className="ac-caseStudyTemplate-overview-star"></Star>
				</div>
				<div
					className="ac-caseStudyTemplate-wave"
					style={{ backgroundImage: `url(${Wave3White})`, bottom: '-136px' }}>
				</div>
			</section>
			<section className="positionRelative ac-caseStudyTemplate-problem">
				<div className="ac-caseStudyTemplate-problem-container">
					<div className="ac-our-stories-problem-content">
						<h2 className='ac-caseStudyTemplate-subheadings textAlignCenter' id='-heading'>Problem</h2>
						<p>{props.problem}</p>
						{/* <ul className='lineHeight3-5'>
							<li>{props.problemDifficulty}</li>
							<li>{props.problemConstraints}</li>
							<li>{props.problemImportance}</li>
						</ul> */}
					</div>
					<Star className="ac-caseStudyTemplate-problem-star"></Star>
					<Star className="ac-caseStudyTemplate-problem-star"></Star>
					<Star className="ac-caseStudyTemplate-problem-star"></Star>
					<Star className="ac-caseStudyTemplate-problem-star"></Star>
					<Star className="ac-caseStudyTemplate-problem-star"></Star>
					<Star className="ac-caseStudyTemplate-problem-star"></Star>
				</div>
				<div
					className="ac-caseStudyTemplate-problem-bottom-wave"
					style={{ backgroundImage: `url(${WavePurple})`, bottom: '-135px' }}>
				</div>
			</section>
			<section className="ac-caseStudyTemplate-process" aria-labelledby="process-caseStudyTemplate-heading">
				<div className="positionRelative ac-caseStudyTemplate-process-container">
					<Star className="ac-caseStudyTemplate-process-star"></Star>
					<Star className="ac-caseStudyTemplate-process-star"></Star>
					<Star className="ac-caseStudyTemplate-process-star"></Star>

					<div className="ac-caseStudyTemplate-process-content">
						<h2 className="ac-caseStudyTemplate-subheadings" id="process-caseStudyTemplate-heading">Our process</h2>
						<p className="ac-caseStudyTemplate-process-subtext">{props.process}</p>
						<div className='twoColumnGrid'>
								<img src={props.processImg1} className='processImages' alt='' />
								<img src={props.processImg2} className='processImages' alt='' />
						</div>
					</div>
					<div className="ac-caseStudyTemplate-solution-content">
						<h2 className="textAlignCenter ac-caseStudyTemplate-subheadings">Solution</h2>
						<p className="ac-caseStudyTemplate-process-subtext marginBottom25">{props.solution}</p>
						<ul>
							{props.featuresAndDeliverables.map(feature => {
								return <li>{feature}</li>
							})
							}
						</ul>
						<div>
							<img src={props.solutionImg1} className='processImages' alt='' />
							{/* <img src={props.solutionImg2} className='processImages' alt='' /> */}
						</div>
					</div>
				</div>

				<div
					className="ac-caseStudyTemplate-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>

			{/* <section
				className="ac-caseStudyTemplate-moreInfo"
				aria-labelledby="moreInfo-caseStudyTemplate-heading">
				<div className="ac-caseStudyTemplate-moreInfo-container">
					<h2 className="ac-caseStudyTemplate-subheadings" id="moreInfo-caseStudyTemplate-heading">
						Get more info
					</h2>
					<div className="ac-caseStudyTemplate-moreInfo-content">
						<div>
							<FontAwesomeIcon icon={faCodeBranch} className="ac-our-team-multiply-icons" />
							<p className="ac-caseStudyTemplate-moreInfo-body">
								Learn more about how our engineers effect change.</p>
							<CustomLink
								to="/hire-us/our-process"
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Preview Our Process"
								internal
								className="ac-caseStudyTemplate-moreInfo-button"></CustomLink>
						</div>
						<div>
							<FontAwesomeIcon icon={faHeadset} className="ac-caseStudyTemplate-moreInfo-icons" />
							<p className="ac-caseStudyTemplate-moreInfo-body">
								Let's discuss how our engineers can solve your technology problems.</p>
							<CustomLink
								to="/hire-us/set-up-a-consult"
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Set Up A Consult"
								internal
								className="ac-caseStudyTemplate-moreInfo-button"></CustomLink>
						</div>
					</div>
				</div>
			</section> */}
			<Footer></Footer>
		</div>
	);
}

export default CaseStudyTemplate;