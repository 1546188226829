import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Carousel from '../../components/carousel/carousel';
import Input from '../../components/input/input';
import Textarea from '../../components/textarea/textArea';
import Button from '../../components/button/button';
import Checkbox from '../../components/checkbox/checkbox';
import Navigation from '../../components/navigation/navigation';
// Assets
import Wave3White from '../../assets/waves/wave-3-white.svg';
import Mission2 from '../../assets/photos/mission-2.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactComponent as Star} from '../../assets/graphics/AC-logo-asterisk.svg';
import {
	faHandHoldingHeart,
	faHandsHelping,
} from '@fortawesome/free-solid-svg-icons';
import referPartner from '../../assets/jobPdfs/ReferPartner.pdf'
import QuotesIcon from '../../assets/graphics/quotes.svg';
const encode = data => {
	return Object.keys(data)
	  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
	  .join("&");
  };

const referPartnerSlides = [
	{
		image: QuotesIcon,
		description:
			'Making the decision to attend the AnnieCannons coding boot camp was honestly a last resort for me and one of the best decisions I have ever made. They saw my potential, believed in me, had much patience, and taught me the skills I needed to become a successful software engineer. Which then provided me with the tools and income I needed to move towards a more safe, healthy, and stable life. For that I am forever grateful.',
		author: "Da'Kirah",
		age: '27',
	},
	{
		image: QuotesIcon,
		description:
			'I remember [when I] qualified to move forward in the admissions process. I could not believe it. It has [now] been about a year since I [became] a part of the AnnieCannons team. AnnieCannons gives us every reason to keep going, keep learning, and be our own boss by providing us with lifetime trainings [to] stay up-to-date with [the] skills required to be the BEST qualified developers we can be!',
		author: 'LethaLE',
		age: '24',
	},
	{
		image: QuotesIcon,
		description:
			'When I first came to AnnieCannons, I [doubted] myself and felt that I had no purpose at all. As a survivor of human trafficking, I thought that my life was over. AnnieCannons changed my life 180 degrees. I found new meaning and purpose [to] my existence, and never had I imagined that I could be that badass engineer that I always wanted to be.',
		author: 'Supergirl',
	},
	// {
	// 	image: QuotesIcon,
	// 	description:
	// 		"When I think about AnnieCannons, the first thing that comes to mind is 'true freedom'. I'm free to exist, think, and speak. I'm free to learn. Free to excel. Free to do whatever I want for the rest of my life.",
	// 	author: 'Morys',
	// 	age: '33',
	// },
];

function ReferPartner() {
	const [userObject, setUserObject] = React.useState({});
	const [submitted, setSubmitted] = React.useState(false);


	const handleChange = e => {
		var userNewObject = userObject;
		userNewObject[e.target.name] = e.target.value
		setUserObject(userNewObject);
		console.log(submitted)
	}


	const handleSubmit = e => {
		console.log(userObject)
		fetch("/", {
		  method: "POST",
		  headers: { "Content-Type": "application/x-www-form-urlencoded" },
		  body: encode({ "form-name": "referpartner", ...userObject })
		})
		.then((response) => {
			setSubmitted(true)
			setUserObject({})
			})
		  .catch(error => alert(error));
	
		e.preventDefault();
	  };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="ac-refer-partner">
			<Navigation selected='get involved'></Navigation>
			<section className="ac-refer-partner-hero" aria-labelledby="hero-heading">
				<div className="ac-refer-partner-hero-container">
					<h1 className="ac-refer-partner-heading" id="hero-heading">
						Become a referral partner
					</h1>
					<p className="desktopWidth45Percent ac-refer-partner-body">
						We are happy to discuss connecting individual to our program. <a href={referPartner} style={{color: 'white'}} target='_blank' rel='noopener noreferrer'>Here is information about our referral partnerships.</a>
					</p>
				</div>
				<Star className="ac-refer-partner-hero-star"></Star>
				<Star className="ac-refer-partner-hero-star"></Star>
			</section>
			<section
				className="ac-refer-partner-engineers"
				aria-labelledby="engineers-heading">
				<div className="ac-refer-partner-engineers-container">
					<div className="ac-refer-partner-mission-content">
						<h2 className="ac-refer-partner-subheading" id="engineers-heading">
							What makes our engineers unique
						</h2>
						<p className="ac-refer-partner-body">
						Participants of diverse educational backgrounds and experiences have been successful in our program. For this reason, we do not have minimum traditional education requirements. We do, however, seek referrals of candidates who identify as a survivor of human trafficking. AnnieCannons' training program is rigorous and requires commitment and drive for participants to succeed. The more interest and motivation participants have in the program, the more they will get out of it.
						</p>
					</div>
					<img src={Mission2} alt=""></img>
				</div>
				<div
					className="ac-refer-partner-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
				<Star className="ac-refer-partner-engineers-star"></Star>
			</section>
			<section
				className="ac-refer-partner-model"
				aria-labelledby="model-heading">
				<div className="ac-refer-partner-model-container">
					<div className="ac-refer-partner-mission-content">
						<h2 className="ac-refer-partner-subheading" id="model-heading">
							Our model
						</h2>
						<p className="ac-refer-partner-body">
						The AnnieCannons Coding Training Program trains and prepares survivors of human trafficking for careers in tech. Our trauma-responsive program assumes no prior computer or coding experience. <br/><br/>  The program is cost-free for participants. <br/> <br/> After referral, candidates submit an application and are invited to schedule an interview designed to assess aptitude for success in our program. In order to best support participants, we keep class sizes small and accept a maximum of 15 participants into each module. Candidates who are not accepted into a given module may be encouraged to apply again.
						</p>
					</div>
					{/* <ul>
						<li>
							<CustomLink
								external
								to="https://anniecannons.org/studentintake"
								label="See application process"></CustomLink>
						</li>
						<li>
							<CustomLink
								external
								to=""
								label="See ideal candidate criteria"></CustomLink>
						</li>
						<li>
							<CustomLink external to="https://anniecannons.org/train" label="See curriculum"></CustomLink>
						</li>
					</ul> */}
				</div>
			</section>
			<section
				className="ac-refer-partner-hear-engineers"
				aria-labelledby="hear-engineers-heading">
				<div className="ac-refer-partner-hear-engineers-container">
					<h2
						className="ac-refer-partner-subheading"
						id="hear-engineers-heading">
						Hear what our engineers say
					</h2>
					<Carousel
						slides={referPartnerSlides}
						darkMode
						variant="quote"></Carousel>
				</div>
			</section>
			<section
				className="ac-refer-partner-become-partner"
				aria-labelledby="become-partner-heading">
				<div className="ac-refer-partner-become-partner-container">
				{submitted ? (
						<div className="ac-refer-partner-become-partner-form">
						<h2
						className="ac-volunteer-subheading"
						id="become-partner-heading">
						Thank you for submitting!
						</h2>
						</div>
					) : (
					<form className="ac-refer-partner-become-partner-form" onSubmit={handleSubmit}>
						<h2
							className="ac-refer-partner-subheading"
							id="become-partner-heading">
							Become a referral partner
						</h2>
						{/* <p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod.
						</p> */}
						<div className="ac-refer-partner-become-partner-form-container">
							<div className="ac-refer-partner-become-partner-form-column">
								<Input label="First name" name="firstName" onChange={handleChange} required></Input>
								<Input label="Last name" name="lastName" onChange={handleChange} required></Input>
								<Input label="Organization" name="organization" onChange={handleChange} required></Input>
								<Input label="Email" name="userEmail" required onChange={handleChange}></Input>
							</div>
							<div className="ac-refer-partner-become-partner-form-column">
								<Textarea name="userMessage" label="Your message" onChange={handleChange} required></Textarea>
								<Checkbox label="I’d like to receive additional information regarding AnnieCannons"></Checkbox>
								<Button label="Submit" variant="primary"></Button>
							</div>
						</div>
					</form>)}
				</div>
				<Star className="ac-refer-partner-become-partner-star"></Star>
				<Star className="ac-refer-partner-become-partner-star"></Star>
				<Star className="ac-refer-partner-become-partner-star"></Star>
			</section>
			<section
				className="ac-refer-partner-impact"
				aria-labelledby="impact-heading">
				<div className="ac-refer-partner-impact-container">
					<h2 className="ac-refer-partner-subheading" id="impact-heading">
						More ways to make an impact
					</h2>
					<div className="ac-refer-partner-impact-content">
						<div>
							<FontAwesomeIcon icon={faHandHoldingHeart} />
							<p className="ac-refer-partner-body">
								Support our engineering training program through a donation.
							</p>
							<CustomLink
								to="/get-involved/become-a-donor"
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Donate"
								internal></CustomLink>
						</div>
						<div>
							<FontAwesomeIcon icon={faHandsHelping} />
							<p className="ac-refer-partner-body">
								Dedicate your time and skills to enable our engineers to excel.
							</p>
							<CustomLink
								to="/get-involved/become-a-volunteer"
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Volunteer"
								internal></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default ReferPartner;
