import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
import Button from '../../components/button/button';
import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
// Assets
import Wave3White from '../../assets/waves/wave-3-white.svg';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
import webAppIcon from '../../assets/photos/LisaHealth-Case-Study.jpg';
import resourceFull from '../../assets/graphics/ResourceFull.png';
import sugarJar from '../../assets/photos/sugarjar.jpeg';
import Allies from '../../assets/graphics/lighthouse1.png';
// import mobileAppIcon from '../../assets/photos/EasyTro-Case-Study.png';
import mobileAppIcon from '../../assets/graphics/Aya1.png';

import websiteIcon from '../../assets/photos/Change_A_Path_Case_Study.jpg';
import curriculum3 from '../../assets/graphics/curriculum/curriculum3.jpg';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
// 	faHeadset,
// 	faCodeBranch,
// } from '@fortawesome/free-solid-svg-icons';



const CaseStudies = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ac-caseStudies">
			<Navigation selected='our services'></Navigation>
			<section className="overFlowHide ac-caseStudies-hero" aria-labelledby="hero-heading">
				<div className="ac-caseStudies-hero-container">
					<h1 className="ac-caseStudies-heading" id="hero-heading">Community Connections</h1>
					<p className="desktopWidth45Percent ac-caseStudies-text">Our participants contribute code to social impact projects in our innovative classroom. </p>
				</div>
				<Star className="ac-caseStudies-hero-star"></Star>
				<Star className="ac-caseStudies-hero-star"></Star>
				<Star className="ac-caseStudies-hero-star"></Star>
			</section>
            <section
				className="ac-our-mission-our-story"
				aria-labelledby="our-story-heading">
				<div className="ac-our-mission-our-story-container">
					<div className="ac-our-mission-content">
						<h2 className="ac-our-mission-subheading" id="engineers-heading">
							Our model
						</h2>
						<p className="ac-our-mission-body">
						During the advanced learning modules, participants will enter into experiential learning coursework. This model uses project-based learning techniques and experiential learning which allows participants to take what they learn in the classroom and apply it in a real-world setting where they grapple with coding challenges, discover and test solutions, and interact with structured teams.  
						<br />
							<br />
							These projects help participants learn about version control, the software lifecycle, code collaboration, and how to work with product teams. Participants are expected to contribute code, attend technical meetings such as standups, sprint reviews, and retrospective meetings, and write technical documentation. By working on real projects in the structure of real tech companies, participants are better prepared for future roles as well as job interviews.

						</p>
						{/* <Button label="Learn More About Our Curriculum" variant="primary" href="https://anniecannons.org/our-work/our-curriculum" ></Button> */}
					</div>
					<img src={curriculum3} alt="Books and Pencils"></img>
				</div>

				<div
					className="ac-our-mission-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
            
            <section
				className="ac-caseStudies-apps-section"
				aria-labelledby="apps-heading">
				<div className="ac-caseStudies-apps-section-container">
					<div className="ac-caseStudies-apps-content">
                    <h2 className="marginBottom40 ac-our-mission-history-subheading" id="history-heading">Our Products</h2>
						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=''>
								<h2 className="ac-caseStudies-subheading">ResourceFull</h2>
								<p className="ac-caseStudies-text">ResourceFull is an AnnieCannons-led initiative to help connect survivors to service providers and resources more safely and efficiently. </p>
								<a href="https://resourcefullapp.org" target="_blank" rel="noreferrer"><Button label="Learn More" variant="primary"  ></Button></a>
							</div>
							<img src={resourceFull} className='appImages webApp' alt="app" />
						</div>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">Lighthouse</h2>
								<p className="ac-caseStudies-text">Allies Against Slavery partners with AnnieCannons to create a platform dedicated to data analysis and visualization of anti-trafficking efforts across Texas and Louisiana.</p>
								<a href="https://alliesagainstslavery.org/lighthouse" target="_blank" rel="noreferrer"><Button label="Learn More" variant="primary"></Button></a>
							</div>
							<img src={Allies} className='appImages mobileApp' alt="Mobile app" />

							<Star className="ac-caseStudies-apps-2-star"></Star>
							<Star className="ac-caseStudies-apps-2-star"></Star>
						</div>
					</div>

				</div>

				<div
					className="ac-caseStudies-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
			<section
				className="ac-caseStudies-apps-section"
				aria-labelledby="apps-heading" style={{
                    marginBottom: '100px',
                }}>
				<div className="ac-caseStudies-apps-section-container">
					<div className="ac-caseStudies-apps-content">
                    <h2 className="marginBottom40 ac-our-mission-history-subheading" id="history-heading">Our Portfolio</h2>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=''>
								<h2 className="ac-caseStudies-subheading">Aya Contigo</h2>
								<p className="ac-caseStudies-text">AnnieCannons partnered with Vitala Global to create an application that accompanies  individuals to safely self-manage an abortion with medication and contraception decision-making tailored to their needs.</p>
								<CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/about/our-portfolio/aya-contigo'}></CustomLink>
							</div>
							<img src={mobileAppIcon} className='appImages webApp' alt="app" />
						</div>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">Lisa Health Web App</h2>
								<p className="ac-caseStudies-text">Envisioning a world where women's health is a global priority, Lisa Health enlisted our support to build an app that equips midlife women to better manage their well-being during the difficult transition of menopause.</p>
								<CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/about/our-portfolio/lisa-health'}></CustomLink>
							</div>
							<img src={webAppIcon} className='appImages mobileApp' alt="Mobile app" />

							<Star className="ac-caseStudies-apps-2-star"></Star>
							<Star className="ac-caseStudies-apps-2-star"></Star>
						</div>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							
						<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">Change a Path Website</h2>
								<p className="ac-caseStudies-text">Starting from the groud up, AnnieCannons collaborarted with Change a Path to develop a website that prioritized clear commuication, ease of navigation, and a simple payment function for donors.</p>
								<CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/about/our-portfolio/change-a-path'}></CustomLink>
							</div>
							<img src={websiteIcon} className='appImages websites' alt="Websites" />

							<Star className="ac-caseStudies-apps-3-star"></Star>
							<Star className="ac-caseStudies-apps-3-star"></Star>
						</div>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
{/* 
							<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">The Sugar Jar Community App</h2>
								<p className="ac-caseStudies-text">Our team worked with Yasmine Cheyenne to develop a safe space for users to develop self-awareness, learn boundaries, and find tools and resources to support individuals on their self-healing journey.</p>
								<CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/about/our-portfolio/sugar-jar'}></CustomLink>
							</div>
							<img src={websiteIcon} className='appImages websites' alt="Websites" /> */}
						</div>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=''>
								<h2 className="ac-caseStudies-subheading">The Sugar Jar Community App</h2>
								<p className="ac-caseStudies-text">Our team worked with Yasmine Cheyenne to develop a safe space for users to develop self-awareness, learn boundaries, and find tools and resources to support individuals on their self-healing journey.</p>
								<CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/about/our-portfolio/sugar-jar'}></CustomLink>
							</div>
							<img src={sugarJar} className='appImages webApp' alt="app" />
						</div>
					</div>

				</div>

				<div
					className="ac-caseStudies-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
			{/* <section
				className="ac-caseStudies-moreInfo"
				aria-labelledby="moreInfo-heading">
				<div className="ac-caseStudies-moreInfo-container">
					<h2 className="marginBottom40 ac-caseStudies-subheading moreInfo" id="moreInfo-heading">
						Get more info
					</h2>
					<div className="ac-caseStudies-moreInfo-content">
						<div className='positionRelative'>
							<FontAwesomeIcon icon={faCodeBranch} className='moreInfoIcon' />
							<p className="ac-caseStudies-text">Learn more about how engineers effect change.</p>
							<div className='absoluteBottom'>
								<CustomLink
									to='/hire-us/our-process'
									buttonStyle
									buttonVariant="primary"
									darkMode
									label="Preview our process"
									internal
									className="ac-caseStudies-process-btn"></CustomLink>
							</div>
						</div>
						<div>
							<FontAwesomeIcon icon={faHeadset} className='moreInfoIcon' />
							<p className="ac-caseStudies-text">Let's discuss how our engineers can solve your technology problems.</p>
							<CustomLink
								to='/hire-us/set-up-a-consult'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Set up a consult"
								internal
								className="ac-caseStudies-consult-btn"></CustomLink>
						</div>
					</div>
				</div>
			</section> */}
			<Footer></Footer>
		</div>
	);
}

export default CaseStudies;
