import React, { useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
//Components
import Navigation from '../../components/navigation/navigation';
import Footer from '../../components/footer/footer';
import CustomLink from '../../components/custom-link/customLink';

//Assets
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
//import Wave1 from '../../assets/waves/wave-1-white.svg';
//import Wave3White from '../../assets/waves/wave-3-white.svg';
//import WavePurple from '../../assets/waves/wave-3-purple.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHandsHelping,
	faNewspaper
} from '@fortawesome/free-solid-svg-icons';
import ThreeAtTable from '../../assets/photos/become-donor-1.png';
import McGovern from '../../assets/logos/funder/McGovernLogo.jpeg';
import Cisco from '../../assets/logos/funder/CiscoHoriz.png';
import Motorola from '../../assets/logos/funder/MotorolaFoundationcolor.png';
import Jensen from '../../assets/logos/funder/TheJensenProjectLogoPurple.png';
import { Helmet } from "react-helmet"

function BecomeADonor() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="ac-become-a-donor">
			<Navigation selected='get involved'></Navigation>
			<section className="ac-become-a-donor-hero" aria-labelledby="hero-heading">
				<div className="ac-become-a-donor-hero-container">
					<h1 className="ac-become-a-donor-heading" id="hero-heading">
						Become a donor
					</h1>
					<p className="desktopWidth50Percent ac-become-a-donor-body">
						Support our engineers advance quickly with your contribution to our high-skilled and transformative programs</p>
				</div>
				<Star className="ac-become-a-donor-hero-star"></Star>
				<Star className="ac-become-a-donor-hero-star"></Star>
				<Star className="ac-become-a-donor-hero-star"></Star>
				<Star className="ac-become-a-donor-hero-star"></Star>
			</section>
			{/* <section className="ac-become-a-donor-donate">
				<div className="positionRelative ac-become-a-donor-donate-container">
					<Star className="ac-become-a-donor-donate-star"></Star>
					<Star className="ac-become-a-donor-donate-star"></Star>
					<h2 className="ac-become-a-donor-donate-subheading" id="support-mission-heading">
						Donate
					</h2>
					<p className="ac-become-a-donor-donate-subtext">Whether it's a one-time contribution ongoing support, it's essential for our success.</p>
					<p className="ac-become-a-donor-donate-subtext">Have any questions? <Link to='/get-involved/contact-us' className='tealLinks'>Contact us</Link></p>
					<div className="positionRelative flexColumnCentered ac-become-a-donor-donate-content">
						<iframe
							title="Donation Portal"
							className="donate-iframe"
							allowpaymentrequest=""
							frameBorder="0"
							name="donorbox"
							scrolling="no"
							seamless="seamless"
							src="https://donorbox.org/embed/8e8cecc2-7d9d-445c-b383-2c47617dfea6"
							style={{
								maxWidth: '500px',
								minWidth: '310px',
								width: '100%',
								minHeight: '1020px',
								float: 'right',
							}}
						></iframe>
					</div>
				</div>
			</section> */}
			<section className="ac-become-a-donor-donate">
				<div className="positionRelative ac-become-a-donor-donate-container">
					<Star className="ac-become-a-donor-donate-star"></Star>
					<Star className="ac-become-a-donor-donate-star"></Star>
					<h2 className="ac-become-a-donor-donate-subheading" id="support-mission-heading">
						Donate
					</h2>
					{/* <p className="ac-become-a-donor-donate-subtext">Support our mission through a monthly or one-time gift.</p> */}
					<p className="ac-become-a-donor-donate-subtext">Support our mission through monthly giving, a one-time gift, or join our <a href="https://www.justgiving.com/campaign/stellarcircle" target="_blank" rel="noreferrer">Stellar Circle</a>.</p>
					<p className="ac-become-a-donor-donate-subtext">Have any questions? <Link to='/get-involved/contact-us' className='tealLinks'>Contact us</Link></p>
					<div className="positionRelative flexColumnCentered ac-become-a-donor-donate-content" style={{display: 'block'}}>
					<Helmet>
					<script src="https://sky.blackbaudcdn.net/static/donor-form-loader/2/main.js"></script>
  					<script>BBDonorFormLoader.newBlackbaudDonationFormZoned('renxt', 'p-1Kna-SPVBUil5UokV4_hYg', 'bbb0d979-a7df-42b1-8f3f-7a1f78765fe3', 'usa')</script>
					</Helmet>
					<div id="blackbaud-donation-form_bbb0d979-a7df-42b1-8f3f-7a1f78765fe3" style={{display: 'flex', justifyContent: 'center'}}></div>
					
        			</div>
				</div>
			</section>
			<section className="positionRelative ac-become-a-donor-know-impact">
				<div className="ac-become-a-donor-know-impact-container">
					<div className="ac-our-stories-know-impact-content-box">
						<div className="ac-our-stories-know-impact-image">
							<img src={ThreeAtTable} alt="Three people sitting at a table on the same side looking above the camera line"></img>
						</div>
						<div className="ac-become-a-donor-know-impact-copy">
							<h2>
								Know where you make an impact
                            </h2>
							<p>
								Your donation helps us with:
                                <ul>
									<li>- Training engineers to gain advanced skills</li>
									<li>- Growing our emerging workforce</li>
									<li>- Improving our curriculum and building our graduate instruction team</li>
									<li>- Paid training projects</li>
									<li>- Staffing expenses</li>
									<li>- Professional and leadership development</li>
								</ul>
							</p>
							<CustomLink
								buttonStyle
								buttonVariant="bare"
								to="/our-purpose/our-mission"
								label="Explore Our Mission"
								darkMode
							/>
						</div>
					</div>
				</div>
				{/* <div
					className="ac-become-a-donor-impact-bottom-wave"
					style={{ backgroundImage: `url(${WavePurple})`, bottom: '-135px'}}>
				</div> */}
			</section>
			<section
				className="positionRelative ac-become-a-donor-success">
				<div className="ac-become-a-donor-success-container">
					<h2 className="ac-become-a-donor-success-subheading" id="-heading">
						How you can help
						</h2>
					<p className="ac-become-a-donor-subtext">Here are different ways that you can become a partner in the fight for dignified work for all.</p>
					<div className="ac-become-a-donor-success-content">
						<div>
							<h1 className="ac-become-a-donor-stat">$50</h1>
							<p className="ac-become-a-donor-stat-textbox">1 kit for course completion of our program. This includes a year of hosting for their portfolio websites and a Cracking the Code book. All of our graduates receive ongoing access to the Udemy platform and our Alumni network.</p>
						</div>
						<div>
							<h1 className="ac-become-a-donor-stat">$100</h1>
							<p className="ac-become-a-donor-stat-textbox"> 6 months of wifi access for a student to participate in our Digital Literacy and Introduction to Programming courses. Expanding wifi access to all of our students is critical for leveling the playing field in tech.</p>
						</div>
						<div>
							<h1 className="ac-become-a-donor-stat">$250</h1>
							<p className="ac-become-a-donor-stat-textbox">Training materials for a full class cycle, including books and tech conference access. These materials provide the building blocks for our students to dive into technology topics and interact with professionals in the field.</p>
						</div>
						<div>
							<h1 className="ac-become-a-donor-stat">$500</h1>
							<p className="ac-become-a-donor-stat-textbox">This covers two months of health benefits for a student while they are participating in advanced classes. We help offset living expenses while students are going through advanced training and, by providing benefits, AnnieCannons is able to help provide support and stability while individuals are learning and preparing for employment.</p>
						</div>
						<div>
							<h1 className="ac-become-a-donor-stat">$1000</h1>
							<p className="ac-become-a-donor-stat-textbox">A new computer, a year of wifi, and office equipment for a student. Students are able to keep this important hardware upon completing the training. Students use these materials to not only learn, but to apply for jobs and start their careers in tech.</p>
						</div>
						<div>
							<h1 className="ac-become-a-donor-stat">$2000</h1>
							<p className="ac-become-a-donor-stat-textbox">One month of a student’s stipend to participate in advanced training modules. Our students are compensated for their time in our advanced modules as they work on real projects, learn advanced skills, and develop their resumes.</p>
						</div>
					</div>
				</div>
				{/* <div
					className="ac-become-a-donor-wave"
					style={{ backgroundImage: `url(${Wave3White})`, bottom: '-136px' }}>
				</div> */}
			</section>
			<section
				className="ac-home-become-a-donor"
				aria-labelledby="ac-donor-contributors-heading">
				{/* <div
					className="ac-become-a-donor-contributors-top-wave"
					style={{ backgroundImage: `url(${Wave1})` }}>
				</div> */}
				<div className="ac-home-become-a-donor-container">
					<h2 className="ac-donor-contributors-heading" id="ac-donor-contributors-heading">
						Contributors to our mission
					</h2>
					<div className="ac-home-become-a-donor-content">
						<img
							src={Cisco}
							alt="Cisco"
							className="ac-home-become-a-donor-image"></img>
						<img
							src={Motorola}
							alt="Motorola"
							className="ac-home-become-a-donor-image"></img>
						<img
							src={Jensen}
							alt="Jensen"
							className="ac-home-become-a-donor-image"></img>
						<img
							src={McGovern}
							alt="McGovern"
							className="ac-home-become-a-donor-image"></img>
					</div>
				</div>
			</section>
			<section
				className="ac-become-a-donor-support-mission"
				aria-labelledby="support-mission-heading">
				<div className="ac-become-a-donor-support-mission-container">
					<h2 className="ac-become-a-donor-support-mission-subheading" id="support-mission-heading">
						More ways to make an impact
					</h2>
					<div className="ac-become-a-donor-support-mission-content">
						<div>
							<FontAwesomeIcon icon={faHandsHelping} className="ac-our-team-multiply-icons" />
							<p className="ac-become-a-donor-support-mission-body">
								Dedicate your time and skills to enable our engineers to excel.</p>
							<CustomLink
								to="/get-involved/become-a-volunteer"
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Volunteer"
								internal
								className="ac-become-a-donor-support-mission-button"></CustomLink>
						</div>
						{/* <div>
							<FontAwesomeIcon icon={faNewspaper} className="ac-become-a-donor-support-mission-icons" />
							<p className="ac-become-a-donor-support-mission-body">
								Stay up to date on our journey as we make strides toward our mission.</p>
							<CustomLink
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Join Our Newsletter"
								internal
								className="ac-become-a-donor-support-mission-button"></CustomLink>
						</div> */}
						<div>
							<FontAwesomeIcon icon={faNewspaper} className="ac-become-a-donor-support-mission-icons" />
							<p className="ac-become-a-donor-support-mission-body">
								Talk to your company about hiring our graduates.</p>
							<CustomLink
								to="/get-involved/become-a-hiring-partner"
								buttonStyle
								buttonVariant="secondary"
								darkMode
								label="Learn More"
								internal
								className="ac-become-a-donor-support-mission-button"></CustomLink>
						</div>

					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default BecomeADonor;