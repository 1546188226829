import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
import Button from '../../components/button/button';
import CustomLink from '../../components/custom-link/customLink';
import Input from '../../components/input/input';
import Textarea from '../../components/textarea/textArea';
import Dropdown from '../../components/dropdown/dropdown';
import Card from '../../components/card/card';
import CaseStudy from '../../assets/icons/case-study.svg';
import Carousel from '../../components/carousel/carousel';
import {ReactComponent as ArrowDown} from '../../assets/icons/Arrow - default.svg';

function Demo() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


	return (
		<div className="demos">
			<section className="section" aria-labelledby="typography-heading">
				<h1 className="demos-heading">AnnieCannons Style Guide</h1>
				<h2 className="section-heading" id="typography-heading">
					Typography
				</h2>
				<h1 className="demos-xl-heading">Our mission</h1>
				<h2 className="demos-heading">Software for a better world</h2>
				<h3 className="demos-subheading">We build quality software</h3>
				<p className="demos-body">
					Your support will go towards a good cause. All service fees fund and
					create education and employment opportunities to positively impact the
					lives of marginalized people.
				</p>
			</section>
			<section className="section" aria-labelledby="link-heading">
				<h2 className="section-heading" id="link-heading">
					Link
				</h2>
				<div className="section-subcontainer">
					<CustomLink
						to="https://anniecannons.com/"
						label="External Link"
						external
					/>
					<CustomLink to="learn" label="Internal Link" />
				</div>
				<h2 className="section-heading" id="link-heading">
					Link (button style)
				</h2>
				<div className="section-subcontainer">
					<CustomLink
						buttonStyle
						buttonVariant="primary"
						to="https://anniecannons.com/"
						label="External Link"
						external
					/>
					<CustomLink
						buttonStyle
						buttonVariant="secondary"
						to="/get-involved/refer-partner"
						label="Internal Link"
					/>
					<CustomLink
						buttonStyle
						buttonVariant="bare"
						to="https://anniecannons.com/"
						label="External Link"
						external
					/>
					<CustomLink
						buttonStyle
						buttonVariant="bare"
						to="https://anniecannons.com/"
						label="Disabled"
						disabled
					/>
				</div>
			</section>
			<section
				className="section -dark-mode"
				aria-labelledby="button-dark-mode-heading">
				<h2 className="section-heading" id="button-dark-mode-heading">
					Link Dark Mode
				</h2>
				<div className="section-subcontainer">
					<CustomLink
						buttonStyle
						buttonVariant="secondary"
						to="learn"
						label="Internal Link"
						darkMode
					/>
					<CustomLink
						buttonStyle
						buttonVariant="bare"
						to="https://anniecannons.com/"
						label="External Link"
						external
						darkMode
					/>
				</div>
			</section>
			<section className="section" aria-labelledby="button-heading">
				<h2 className="section-heading" id="button-heading">
					Button
				</h2>
				<div className="section-subcontainer">
					<Button variant="primary" label="Primary"></Button>
					<Button variant="primary" label="Disabled" disabled></Button>
				</div>
				<div className="section-subcontainer">
					<Button variant="secondary" label="Secondary"></Button>
					<Button variant="secondary" label="Disabled" disabled></Button>
				</div>
				<div className="section-subcontainer">
					<Button variant="bare" label="Bare"></Button>
					<Button variant="bare" label="Bare" disabled></Button>
				</div>
				<div className="section-subcontainer">
					<Button
						svg={<ArrowDown className="ac-home-arrow-default"></ArrowDown>}
						ariaConfig={{'aria-label': 'Skip to main content'}}></Button>
				</div>
				<h2 className="section-heading" id="button-heading">
					Button (link style)
				</h2>
				<div className="section-subcontainer">
					<Button linkStyle label="Button with link style"></Button>
					<Button linkStyle label="Button with link style" disabled></Button>
				</div>
			</section>
			<section
				className="section -dark-mode"
				aria-labelledby="button-dark-mode-heading">
				<h2 className="section-heading" id="button-dark-mode-heading">
					Button Dark Mode
				</h2>
				<div className="section-subcontainer">
					<Button variant="secondary" label="Secondary" darkMode></Button>
					<Button variant="secondary" label="Disabled" disabled></Button>
				</div>
				<div className="section-subcontainer">
					<Button variant="bare" label="Bare" darkMode></Button>
					<Button variant="bare" label="Bare" darkMode disabled></Button>
				</div>
			</section>
			<section className="section" aria-labelledby="input-heading">
				<h2 className="section-heading" id="input-heading">
					Input
				</h2>
				<div className="section-subcontainer">
					<Input label="First Name" required></Input>
					<Input label="Middle Name"></Input>
					<Input
						label="Last Name"
						required
						invalid
						message="This field is required."></Input>
					<Input
						label="Address"
						required
						placeholder="Street, City, State, Zipcode"></Input>
				</div>
			</section>
			<section className="section" aria-labelledby="dropdown-heading">
				<h2 className="section-heading" id="dropdown-heading">
					Dropdown
				</h2>
				<div className="section-subcontainer">
					<Dropdown
						label="Favorite fruit"
						placeholder="Select a fruit"
						options={[
							{label: 'Apple', value: 'apple'},
							{label: 'Banana', value: 'banana'},
							{label: 'Orange', value: 'orange'},
						]}></Dropdown>
					<Dropdown
						label="Reason for contact"
						required
						placeholder="Select a reason"
						options={[
							{label: 'Donate', value: 'donate'},
							{label: 'Volunteer', value: 'volunteer'},
							{label: 'Other', value: 'other'},
						]}></Dropdown>
					<Dropdown
						label="Reason for contact"
						required
						placeholder="Select a reason"
						message="This field is required"
						invalid
						options={[
							{label: 'Donate', value: 'donate'},
							{label: 'Volunteer', value: 'volunteer'},
							{label: 'Other', value: 'other'},
						]}></Dropdown>
				</div>
			</section>
			<section className="section" aria-labelledby="textarea-heading">
				<h2 className="section-heading" id="textarea-heading">
					Textarea
				</h2>
				<div className="section-subcontainer">
					<Textarea
						label="Your Message"
						required
						placeholder="Type your comments here..."></Textarea>
					<Textarea
						label="Your Message"
						placeholder="Type your comments here..."></Textarea>
					<Textarea
						label="Your Message"
						required
						invalid
						message="This field is required."
						placeholder="Type your comments here..."></Textarea>
				</div>
			</section>
			<section className="section" aria-labelledby="card-heading">
				<h2 className="section-heading" id="card-heading">
					Card
				</h2>
				<Card
					link={{label: 'Read about this case study', url: ''}}
					description="Vestibulum rutrum quam vitae fringilla tincidunt. 
					Suspendisse nec tortor urna. Ut laoreet sodales nisi, quis 
					iaculis nulla iaculis vitae. Donec sagittis faucibus lacus 
					eget blandit. Mauris vitae ultricies. metus, at condimentum 
					nulla. Donec quis ornare lacus. Etiam gravida mollis tortor 
					quis porttitor."
					imgSrc={CaseStudy}></Card>
			</section>
			<section className="section -carousel" aria-labelledby="carousel-heading">
				<h2 className="section-heading" id="carousel-heading">
					Carousel
				</h2>
				<Carousel
					slides={[
						{
							image: CaseStudy,
							label: 'Case Study 1',
							description:
								'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut laoreet sodales nisi, quis iaculis nulla iaculis vitae. Donec sagittis faucibus lacus eget blandit.',
							link: {label: 'Read about this case study', url: '/case-study'},
						},
						{
							image: CaseStudy,
							label: 'Case Study 2',
							description:
								'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut laoreet sodales nisi, quis iaculis nulla iaculis vitae. Donec sagittis faucibus lacus eget blandit.',
							link: {label: 'Read about this case study', url: '/case-study'},
						},
						{
							image: CaseStudy,
							label: 'Case Study 3',
							description:
								'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut laoreet sodales nisi, quis iaculis nulla iaculis vitae. Donec sagittis faucibus lacus eget blandit.',
							link: {label: 'Read about this case study', url: '/case-study'},
						},
						{
							image: CaseStudy,
							label: 'Case Study 4',
							description:
								'Vestibulum rutrum quam vitae fringilla tincidunt. Suspendisse nec tortor urna. Ut laoreet sodales nisi, quis iaculis nulla iaculis vitae. Donec sagittis faucibus lacus eget blandit.',
							link: {label: 'Read about this case study', url: '/case-study'},
						},
					]}></Carousel>
			</section>
			
		</div>
	);
}

export default Demo;
