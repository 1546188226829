import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import impactImg from '../../../assets/graphics/impact-icon.png'


class ImpactSection extends React.Component {

    render() {
        return (
            <section className="journeySection" id="impact">
                <img src={impactImg} className="tabletIcon" alt="Impact Icon" />
                <Container className="sectionContent">
                    <Row className="sectionRow">
                        <Col xs={12} sm={9} md={9}>
                            <div className="mobileIconWrapper">
                                <h4 className="titleText">COMMUNITY CONNECTION</h4>
                                <img src={impactImg} className="mobileIcon" alt="Impact Icon" />
                            </div>
                            <h4 className="missionText">AnnieCannons provides paid learning opportunities.</h4>
                            <p className="paragraphText">This model uses project-based learning techniques and experiential learning which allows participants to take what they learn in the classroom and apply it in a real world setting where they grapple with real-world coding challenges, discover and test solutions, and interact with structured teams. By working on real projects in the structure of real tech companies, participants are better prepared for future roles as well as job interviews.</p>
                        </Col>
                        <Col xs={12} sm={3} md={3} className="imageContainer">
                            <img src={impactImg} alt="Impact Icon" />
                        </Col>
                    </Row>
                </Container>
                <div className="homeButtonWrapper">
                    <a href="https://resourcefullapp.org/"><button className="purpleBtns">ResourceFull</button></a>
                    <a href='/our-work/community-connection'>
                        <button className="purpleBtns">See Our Portfolio</button>
                    </a>
                </div>
            </section>
        )
    }
}

export default ImpactSection;