import React from 'react';
import Slider from 'react-slick';
import Card from '../card/card';
import OurTeamCarouselCard from '../ourTeamCarouselCard/ourTeamCarouselCard';
import QuoteCard from '../quote-card/quoteCard';
import './styles.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Built with React Slick
// https://react-slick.neostack.com/docs/get-started/

function Carousel({slides, darkMode, variant}) {
	// React Slick settings
	var settings = {
		arrows: variant === 'ourTeamCarousel' ? true : false,
		centerPadding: 0,
		slidesToShow: 1,
		speed: 500,
		dots: true,
		infinite: true,
		centerMode: true,
		slidesToScroll: 1,
		variableWidth: true,
		appendDots: (dots) => (
			<div>
				<ul> {dots} </ul>
			</div>
		),
		customPaging: (i) => <button aria-label={`Slide ${i + 1}`}></button>,
	};

	const carouselClass = classNames({
		'ac-carousel': true,
		'-dark-mode': darkMode,
	});

	return (
		<div className={carouselClass}>
			<Slider {...settings}>
				{slides &&
					slides.map((slide, i) => {
						if (variant === 'image') {
							return (
								<Card
									key={slide.label + i}
									label={slide.label}
									title={slide.title}
									description={slide.description}
									link={slide.link}
									imgSrc={slide.image}></Card>
							)
						} else if (variant === 'ourTeamCarousel') {
							return (
								<OurTeamCarouselCard
									key={slide.label + i}
									label={slide.label}
									description={slide.description}
									link={slide.link}
									imgSrc={slide.image}></OurTeamCarouselCard>
							)
						} else {
							return (
								<QuoteCard
									key={slide.label + i}
									label={slide.label}
									description={slide.description}
									author={slide.author}
									age={slide.age}></QuoteCard>
							)
						}
					})}
			</Slider>
		</div>
	);
}

Carousel.propTypes = {
	// Accepts an array of Cards or QuoteCards to show as slides
	slides: PropTypes.array.isRequired,
	// Use on dark backgrounds
	darkMode: PropTypes.bool,
	// Currently accepts one variant 'quote'. Can be extended to include different
	// kinds of cards.
	variant: PropTypes.oneOf(['quote', 'image', 'ourTeamCarousel']),
};

Carousel.defaultProps = {
	slides: [],
	darkMode: false,
	variant: '',
};

export default Carousel;
