import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import StyleGuide from './pages/style-guide/styleGuide';
import Home from './pages/home/home';
import ReferPartner from './pages/refer-partner/referPartner';
import OurPurpose from './pages/our-mission/ourMission';
import OurTeam from './pages/our-team/ourTeam';
import ACPress from './pages/ac-in-the-press/acPress';
import Careers from './pages/careers/careers';
import OurImpact from './pages/our-impact/ourImpact';
import BecomeADonor from './pages/become-a-donor/becomeADonor';
import BecomeAHiringPartner from './pages/become-a-hiring-partner/becomeAHiringPartner';
import Volunteer from './pages/become-a-volunteer/becomeAVolunteer';
import SetUpAConsult from './pages/setUpAConsult/setUpAConsult';
//import WhatWeBuild from './pages/whatWeBuild/whatWeBuild';
//import CaseStudies from './pages/caseStudies/caseStudies';
import EasyTro from './pages/caseStudyTemplate/easyTro';
import LisaHealth from './pages/caseStudyTemplate/lisaHealth';
import ChangeAPath from './pages/caseStudyTemplate/changeAPath';
import AyaContigo from './pages/caseStudyTemplate/vitalaGlobal';
import SugarJar from './pages/caseStudyTemplate/sugarJar';
import Admissions from './pages/admissions/admissions';
//import OurProcess from './pages/ourProcess/ourProcess';
import CodingTrainingProgram from './pages/coding-training-program/codingTrainingProgram'
import JobReadiness from './pages/job-readiness/jobReadiness'
import CommunityConnection from './pages/community-connection/communityConnection'
import OurCurriculum from './pages/our-curriculum/ourCurriculum';

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/style-guide" component={StyleGuide} />
        <Route
          path="/our-purpose/our-mission"
          component={OurPurpose}></Route>   
        <Route
          path="/our-purpose/our-impact"
          component={OurImpact}></Route>

        <Route
          path="/our-work/coding-training-program"
          component={CodingTrainingProgram}></Route>
        <Route
          exact path="/our-work/job-readiness" 
          component={JobReadiness}></Route>
        <Route
          exact path="/our-work/community-connection" 
          component={CommunityConnection}></Route>
        <Route
          exact path="/our-work/our-curriculum" 
          component={OurCurriculum}></Route>
        <Route
          exact path="/our-work/ac-in-the-press" 
          component={ACPress}></Route>

        <Route
          exact path="/about/our-team" 
          component={OurTeam}></Route>
        <Route
          path="/about/our-careers"
          component={Careers}></Route>
        {/* <Route
          path="/about/our-blog"
          component={LisaHealth}></Route> */}
        {/* <Route
          path="/about/our-portfolio"
          component={CaseStudies}></Route> */}

        <Route
          path="/about/our-portfolio/easyTRO"
          component={EasyTro}></Route>
        <Route
          path="/about/our-portfolio/lisa-health"
          component={LisaHealth}></Route>
        <Route
          path="/about/our-portfolio/change-a-path"
          component={ChangeAPath}></Route>
          <Route
          path="/about/our-portfolio/aya-contigo"
          component={AyaContigo}></Route>
         <Route
          path="/about/our-portfolio/sugar-jar"
          component={SugarJar}></Route>

        <Route
          path="/admissions"
          component={Admissions}></Route>
        
        <Route
          path="/get-involved/become-a-hiring-partner"
          component={BecomeAHiringPartner}></Route>
        <Route
          path="/get-involved/become-a-donor"
          component={BecomeADonor}></Route>
        <Route
          path="/get-involved/become-a-volunteer"
          component={Volunteer}></Route>
        <Route
          path="/get-involved/refer-partner"
          component={ReferPartner}></Route>
          <Route
          path="/get-involved/contact-us"
          component={SetUpAConsult}></Route>
      </Switch>
    </div>
  );
}

export default App;

