import React from 'react';
//import './styles.scss';
import './styles.css';
//Components
import Navigation from '../../components/navigation/navigation';
import Footer from '../../components/footer/footer';

//import { Container, Col, Row } from "react-bootstrap";
import curriculumModules from "./curriculumItems";



function ourCurriculum() {

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);


	return (
		<div className="trainSection">
			<Navigation selected='our purpose'></Navigation>
			<div className="sectionHeader">
          <h2>ANNIECANNONS' CORE CURRICULUM</h2>
          <p className="colorUranus noMarginB">Our trauma-informed curriculum is built to be customized for each classroom. We make our latest curriculum available for free to other organizations to train talented individuals to code.</p>
        </div>

        {/* <Container fluid>
          <Row> */}
            <div style= {{display:'flex', flexWrap: 'wrap'}}>
              {curriculumModules.map((item, key) => {
                return (
                <div className="imageColumn" key ={key}>
                <a href={`https://github.com/AnnieCannons/${item.link}`}>
                  <img
                    src={item.poster}
                    className="imageCurricula"
                    alt="Curricula Poster"
                  />
                  <div className="imageCard montserrat">
                    <p>{item.title}</p>
                  </div>
                </a>
              </div>
                )
              })}
              
              </div>
            {/* ))} */}
          {/* </Row>
        </Container> */}
			<Footer></Footer>
		</div>
	);
}

export default ourCurriculum;