import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import trainingImg from '../../../assets/graphics/training-icon.png'


class TrainingSection extends React.Component {

    render() {
        return (
            <section className="journeySection" id="train">
                <img src={trainingImg} className="tabletIcon" alt="Training Icon" />
                <Container className="sectionContent">
                    <Row className="sectionRow">
                        <Col xs={12} sm={9} md={9}>
                            <div className="mobileIconWrapper">
                                <h2 className="titleText">TRAINING</h2>
                                <img src={trainingImg} className="mobileIcon" alt="Training Icon" />
                            </div>
                            <h4 className="missionText">AnnieCannons' educational strategy allows participants to build skills with confidence.</h4>
                            <p className="paragraphText">Our program starts with digital literacy and advances through HTML, CSS, and JavaScript as participants demonstrate mastery. Later phases include full stack development, cybersecurity, mobile application development, and more.</p>
                        </Col>
                        <Col xs={12} sm={3} md={3} className="imageContainer">
                            <img src={trainingImg} alt="Training Icon" />
                        </Col>
                    </Row>
                </Container>
                <div className="homeButtonWrapper">
                    <Link to="/our-purpose/our-mission#ac-our-impact-our-stories"><button className="purpleBtns">What Students Say</button></Link>
                    {/* <Link to='/our-work/our-curriculum'><button className="purpleBtns">See the Curriculum</button></Link> */}
                </div>
            </section>
        )
    }
}

export default TrainingSection;





