import React from 'react';
import CaseStudyTemplate from './caseStudyTemplate';
import heroImg from '../../assets/photos/easyTro-heroImg.png';
// import clientTextLogo from '../../assets/logos/client/';


function EasyTro() {

    return (
        <CaseStudyTemplate
            title='EasyTRO Mobile App'
            subTitle='[Helping <this community with this overall goal or action>]'
            heroImg={heroImg}
            overviewText='[Our engineers teamed up with <client name> for <#> months to <do these key thing>. [Examples can include deliverables.]'
            clientTextLogo={'EasyTRO'}
            clientTextLogoIsText // Remove id have an actual image
            logoAltText={'EasyTRO'}
            client='AnnieCannons'
            industry='Legal'
            projectType='Mobile Application'
            impactMetricNumber1='20%'
            impactMetricText1='Key metric that illustrates success or human impact'
            impactMetricNumber2='18'
            impactMetricText2='Key metric that illustrates success or human impact'
            impactMetricNumber3='24'
            impactMetricText3='Key metric that illustrates success or human impact'
            impactQuote='"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."'
            problem='[Lead with the uncommon difficulty of the problem. Fee free to groud the issue in high-level data.]'
            problemDifficulty='[Show how the probelm was particulary "tough," "complex," "thorny," "challenging," etc.]'
            problemConstraints='[Feel free to highlight any other relevant constraints.]'
            problemImportance='[Convey why this problem or overall issue is important on a human level.]'
            process='[Tell a story about intelligent problem solving, empathy, and close collaboration. Show how the team used "analysis," "understanding," "perception," "insight," etc. And feel free to highlight how the process was iterative.]'
            processImg1={heroImg}
            processImg2={heroImg}
            solution='["Describe what the team created, the skills or technologies involved, and the benefits."]'
            featuresAndDeliverables={
                [
                    '[Key feature or deliverable #1 that shows a specific solution or highlights a use case or example.]',
                    '[Key feature or deliverable #2 that shows a specific solution or highlights a use case or example.]',
                    '[Key feature or deliverable #3 that shows a specific solution or highlights a use case or example.]',
                ]
            }
            solutionImg1={heroImg}
            solutionImg2={heroImg}
        />
    )
}

export default EasyTro;