import React from 'react';
import './styles.scss';
import {NavLink} from 'react-router-dom';
import {NavHashLink} from 'react-router-hash-link';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// Assets
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

function CustomLink(props) {
	const {
		external,
		label,
		to,
		buttonStyle,
		disabled,
		buttonVariant,
		darkMode,
		hideButtonPadding,
		icon,
		ariaConfig,
		hash,
		noIcon,
		selected,
		svg,
	} = props;

	const linkClass = classNames({
		[`ac-${buttonStyle ? 'button' : 'link'}`]: true,
		[`-${buttonVariant}`]: buttonStyle && !icon,
		'-no-padding': hideButtonPadding,
		'-dark-mode': darkMode,
		'is-disabled': disabled,
		'-no-icon': noIcon,
		'-icon': (icon || svg) && !label,
		'is-selected': selected,
		'has-arrow': buttonVariant === 'bare',
	});

	// If a link redirects to a new tab, use <a>
	if (external) {
		return (
			<a
				className={linkClass}
				tabIndex={disabled ? -1 : 0}
				href={to}
				target="_blank"
				rel="noopener noreferrer"
				disabled={disabled}
				{...ariaConfig}>
				<span>{label}</span>
				{icon && <FontAwesomeIcon icon={icon}></FontAwesomeIcon>}
				{svg}
				{buttonVariant && buttonVariant === 'bare' && (
					<FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
				)}
			</a>
		);
	} else if (hash) {
		// If a link contains a hash, use NavHashLink
		return (
			<NavHashLink
				className={linkClass}
				exact
				activeClassName="is-selected"
				to={to}
				tabIndex={disabled ? -1 : 0}
				{...ariaConfig}
				smooth>
				<span>{label}</span>
				{icon && <FontAwesomeIcon icon={icon}></FontAwesomeIcon>}
				{svg}
				{buttonVariant && buttonVariant === 'bare' && (
					<FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
				)}
			</NavHashLink>
		);
	} else {
		// If a link redirects to a page within the same domain, use NavLink
		return (
			<NavLink
				className={linkClass}
				exact
				activeClassName="is-selected"
				to={to}
				tabIndex={disabled ? -1 : 0}
				{...ariaConfig}>
				<span>{label}</span>
				{icon && <FontAwesomeIcon icon={icon}></FontAwesomeIcon>}
				{svg}
				{buttonVariant && buttonVariant === 'bare' && !noIcon && (
					<FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
				)}
			</NavLink>
		);
	}
}

CustomLink.propTypes = {
	// Adds aria attributes
	ariaConfig: PropTypes.object,
	// Allow link to be styled as a Style Guide button
	buttonStyle: PropTypes.bool,
	// Button variants
	buttonVariant: PropTypes.oneOf(['primary', 'secondary', 'bare']),
	// Use on dark backgrounds
	darkMode: PropTypes.bool,
	// Link is unclickable/unusable
	disabled: PropTypes.bool,
	// Denotes that link redirects you to a new tab outside domain
	external: PropTypes.bool,
	// Denotes that link takes you to a section of a page
	hash: PropTypes.bool,
	// Removes padding on button-styled links
	hideButtonPadding: PropTypes.bool,
	// Link with icon
	icon: PropTypes.object,
	// Link label text
	label: PropTypes.string,
	// Has no icon
	noIcon: PropTypes.bool,
	// Link is active/selected
	selected: PropTypes.bool,
	// Link url
	to: PropTypes.string.isRequired,
	// Link with svg as an icon
	svg: PropTypes.object,
};

CustomLink.defaultProps = {
	ariaConfig: null,
	buttonStyle: false,
	darkMode: false,
	disabled: false,
	external: false,
	hash: false,
	hideButtonPadding: false,
	icon: null,
	label: '',
	noIcon: false,
	selected: false,
	to: '',
	svg: null,
};

export default CustomLink;
