import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import {ReactComponent as Quotes} from '../../assets/graphics/quotes.svg';

function QuoteCard(props) {
	const {description, label, author, age} = props;

	return (
		<div className="ac-quote-card" aria-label={label}>
			<Quotes></Quotes>
			<div className="ac-quote-card-content">
				<div className="ac-quote-card-description">{description}</div>
				<p className="ac-quote-card-author">
					{author}{age ? <span>, {age}</span>:null}
				</p>
			</div>
		</div>
	);
}

QuoteCard.propTypes = {
	// Author of the quote
	author: PropTypes.string,
	// Age of the author
	age: PropTypes.string,
	// Quote
	description: PropTypes.string,
	// Card label text
	label: PropTypes.string,
};

QuoteCard.defaultProps = {
	author: '',
	age: '',
	description: '',
	label: '',
};

export default QuoteCard;
