import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import CustomLink from '../custom-link/customLink.js';

function Card(props) {
	const {description, imgSrc, label, link, title} = props;

	return (
		<div className="ac-card" aria-label={label}>
			<div className="ac-card-image">
				<img src={imgSrc} alt={label}></img>
				<br/>
				<p>{title}</p>
			</div>
			<div className="ac-card-content">
				<div className="ac-card-description">{description}</div>
				{link && (
					<CustomLink
						buttonStyle
						buttonVariant="bare"
						hideButtonPadding
						external={false}
						label={link.label}
						to={link.url}></CustomLink>
				)}
			</div>
		</div>
	);
}

Card.propTypes = {
	// Card description (large body of text)
	description: PropTypes.string.isRequired,
	// Card image source
	imgSrc: PropTypes.string.isRequired,
	// Card label text
	label: PropTypes.string.isRequired,
	// Adds a CustomLink to Card
	link: PropTypes.object,
};

Card.defaultProps = {
	description: '',
	imgSrc: '',
	label: '',
	link: {},
};

export default Card;
