import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import closeIconWhite from '../../assets/icons/close-icon-white.png';
// import { faChartBar, faUsers } from '@fortawesome/free-solid-svg-icons';


function TeamCardModal(props) {
    const { modalIsOpen, closeModal, imgSrc, label, name, title, link, bio } = props;

    return (
        <Modal
            isOpen={modalIsOpen}
            className="ac-team-card-modal"
            onRequestClose={closeModal}
            // style={customStyles}
            // contentLabel = "Example Modal"
        >
            <div className='topNav'>
                <button onClick={closeModal} className='btnNoStyle closeBtn'>
                    <img src={closeIconWhite} alt='close'/>
                </button>
            </div>
            <div className="ac-team-card-modal-content">
                
                <div className='flex1 flexColumnCentered leftColumn'>
                    <div>
                        <img className='marginBottom18 ac-team-card-modal-image' src={imgSrc} alt={label}></img>
                    </div>
                    <div className='flexColumnCentered titleDiv'>
                        <p className="ac-team-card-modal-name">{name}</p>
                        <p className="marginBottom10 ac-team-card-modal-title">{title}</p>
                        <a href={link.url} rel='noopener noreferrer' target='_blank'>
                            <div className="ac-team-card-modal-linkedin"></div>
                        </a>
                    </div>
                </div>

                <div className='flex1 flexColumnCentered rightColumn'>
                    <div className='bioText'>
                        <span>{bio}</span>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

TeamCardModal.propTypes = {
    // Card image source
    imgSrc: PropTypes.string.isRequired,
    // Which color is fist in the color overlay
    firstColor: PropTypes.string.isRequired,
    // Card label text
    label: PropTypes.string.isRequired,
    // Adds a CustomLink to Card
    link: PropTypes.object,
    // Card name text
    name: PropTypes.string.isRequired,
    // Card title text
    title: PropTypes.string.isRequired,
    // bio (large body of text)
    bio: PropTypes.string,
};

TeamCardModal.defaultProps = {
    imgSrc: '',
    firstColor: 'red',
    label: '',
    link: {},
    name: '',
    title: '',
    bio: '',
};

export default TeamCardModal;