import React, { useEffect } from 'react';
import './styles.scss';
import '../../styles/_index.scss';
// Components
import CustomLink from '../../components/custom-link/customLink';
import Footer from '../../components/footer/footer';
import Navigation from '../../components/navigation/navigation';
// Assets
import Wave3White from '../../assets/waves/wave-3-white.svg';
import { ReactComponent as Star } from '../../assets/graphics/AC-logo-asterisk.svg';
import webAppIcon from '../../assets/photos/LisaHealth-Case-Study.jpg';
import mobileAppIcon from '../../assets/photos/EasyTro-Case-Study.png';
import websiteIcon from '../../assets/photos/Change_A_Path_Case_Study.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHeadset,
	faCodeBranch,
} from '@fortawesome/free-solid-svg-icons';



const JobReadiness = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="ac-caseStudies">
			<Navigation selected='our services'></Navigation>
			<section className="overFlowHide ac-caseStudies-hero" aria-labelledby="hero-heading">
				<div className="ac-caseStudies-hero-container">
					<h1 className="ac-caseStudies-heading" id="hero-heading">Job Readiness</h1>
					<p className="desktopWidth45Percent ac-caseStudies-text">Our job readiness program allows our participants to refine their resumes, receive job coaching and create career goals, and prepare for technical interviews.</p>
				</div>
				<Star className="ac-caseStudies-hero-star"></Star>
				<Star className="ac-caseStudies-hero-star"></Star>
				<Star className="ac-caseStudies-hero-star"></Star>
			</section>
			<section
				className="ac-caseStudies-apps-section"
				aria-labelledby="apps-heading">
				<div className="ac-caseStudies-apps-section-container">
					<div className="ac-caseStudies-apps-content">
						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<Star className="ac-caseStudies-apps-1-star"></Star>
							<Star className="ac-caseStudies-apps-1-star"></Star>

							<div className=''>
								<h2 className="ac-caseStudies-subheading">Mentorship</h2>
								<p className="ac-caseStudies-text">We partner with companies like Motorola and Google to facilitate a knowledge exchange and bridge meaningful connections that help to accelerate personal and professional growth in the technology industry, and beyond.  Mentors provide coaching, advice, guidance, and problem-solving support that help participants confidently apply their skills and successfully transition from the classroom into the workplace.</p>
								{/* <CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/our-services/case-studies/easyTro'}></CustomLink> */}
							</div>
							<img src={mobileAppIcon} className='appImages webApp' alt="app" />
						</div>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<div className='desktopMarginRight40'>
								<h2 className="ac-caseStudies-subheading">Workshops</h2>
								<p className="ac-caseStudies-text">Interns participate in workshops such as Advanced Developer Tools, Resume Writing, Building Your Linkedin Profile, Technical Interviewing, Leadership and Inclusive Decision-Making. We also provide coaching sessions to develop professional skills, set career goals, and refine interviewing skills.</p>
								{/* <CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/our-services/case-studies/lisa-health'}></CustomLink> */}
							</div>
							<img src={webAppIcon} className='appImages mobileApp' alt="Mobile app" />

							<Star className="ac-caseStudies-apps-2-star"></Star>
							<Star className="ac-caseStudies-apps-2-star"></Star>
						</div>

						<div className='desktopFlexRow alignItemsCentered mobileTextAlignCenter mobileColumnReversed appsDivs'>
							<Star className="ac-caseStudies-apps-3-star"></Star>
							<Star className="ac-caseStudies-apps-3-star"></Star>

							<div className=''>
								<h2 className="ac-caseStudies-subheading">Advanced Learning</h2>
								<p className="ac-caseStudies-text">Participants also engage in self-learning activities in mobile app development as well as shadowing opportunities to give survivors the chance to learn about new technologies and career paths.</p>
								{/* <CustomLink
									buttonStyle
									buttonVariant="secondary"
									external={false}
									label={'Learn more'}
									to={'/our-services/case-studies/change-a-path'}></CustomLink> */}
							</div>
							<img src={websiteIcon} className='appImages websites' alt="Websites" />
						</div>
                        
					</div>
                    

				</div>

				<div
					className="ac-caseStudies-wave"
					style={{
						backgroundImage: `url(${Wave3White})`,
						bottom: '-100px',
					}}></div>
			</section>
			<section
				className="ac-caseStudies-moreInfo"
				aria-labelledby="moreInfo-heading">
				<div className="ac-caseStudies-moreInfo-container">
					<h2 className="marginBottom40 ac-caseStudies-subheading moreInfo" id="moreInfo-heading">
						Get more info
					</h2>
					<div className="ac-caseStudies-moreInfo-content">
						<div className='positionRelative'>
							<FontAwesomeIcon icon={faCodeBranch} className='moreInfoIcon' />
							<p className="ac-caseStudies-text">Learn more about how to become a student.</p>
							<div className='absoluteBottom'>
								<CustomLink
									to='/admissions'
									buttonStyle
									buttonVariant="primary"
									darkMode
									label="Become a student"
									internal
									className="ac-caseStudies-process-btn"></CustomLink>
							</div>
						</div>
						<div>
							<FontAwesomeIcon icon={faHeadset} className='moreInfoIcon' />
							<p className="ac-caseStudies-text">Learn more about hiring graduates of our program for your company.</p>
							<CustomLink
								to='/get-involved/become-a-hiring-partner'
								buttonStyle
								buttonVariant="primary"
								darkMode
								label="Become a hiring partner"
								internal
								className="ac-caseStudies-consult-btn"></CustomLink>
						</div>
					</div>
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
}

export default JobReadiness;
