import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

import TeamCardModal from './teamCardModal';
// import CustomLink from '../custom-link/customLink';
// import LinkedInIcon from '../../assets/icons/linkedin-icon-teal.png';
// import { LinkedIn } from '@material-ui/icons';
// import { faChartBar, faUsers } from '@fortawesome/free-solid-svg-icons';


function TeamCard (props) {
	const { imgSrc, label, name, title, link, bio } = props;

	const [modalIsOpen, setIsOpen] = useState(false);

	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	return (
		<div className="ac-team-card" aria-label={label}>
			<button className="cursorPointer ac-team-card-image-btn" onClick={openModal}>
				{/* <div className={firstColor === 'red' ? 'redFirst overlayColor' : 'tealFirst overlayColor'}></div> */}
				<img src={imgSrc} alt={label}></img>
			</button>
			<div className="ac-team-card-content">
				<div className="ac-team-card-name">{name}</div>
                <div className="ac-team-card-title">{title}</div>
                <a href={link.url} rel='noopener noreferrer' target='_blank'>

					<div className="ac-team-card-linkedin"></div>
				</a>
			</div>

			<TeamCardModal
				modalIsOpen={modalIsOpen}
				closeModal={closeModal}
				imgSrc={imgSrc}
				label={label}
				name={name}
				title={title}
				link={link}
				bio={bio}
			/>
		</div>
	);
}

TeamCard.propTypes = {
	// Card image source
	imgSrc: PropTypes.string.isRequired,
	// Which color is fist in the color overlay
	firstColor: PropTypes.string.isRequired,
	// Card label text
	label: PropTypes.string.isRequired,
	// Adds a CustomLink to Card
	link: PropTypes.object,
    // Card name text
	name: PropTypes.string.isRequired,
    // Card title text
	title: PropTypes.string.isRequired,
    // bio (large body of text)
	bio: PropTypes.string,
};

TeamCard.defaultProps = {
	imgSrc: '',
	firstColor: 'red',
	label: '',
	link: {},
    name: '',
    title: '',
    bio: '',
};

export default TeamCard;
