import React from "react";
import Section2Video from '../../../assets/graphics/section2-vid.mp4';
import heroImage from '../../../assets/graphics/mobile-hero.png'

class VideoBackground extends React.Component {
    render() {

        return (
            <div>
                <video className="fixedBackgroundVideo" muted loop autoPlay>
                    <source src={Section2Video} type="video/mp4" />
                    <source src={Section2Video} type="video/ogg" /> Your browser does not support the video tag.
                </video>
                <img className="fixedBackgroundImage" src={heroImage} alt="Starry Landscape" />
            </div>
        )
    }
}


export default VideoBackground;