import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

function OurTeamCarouselCard(props) {
    const { description, imgSrc, label } = props;

    return (
        <div className="ac-our-team-carousel-card" aria-label={label}>
            <h2 className="ac-our-team-carousel-card-heading" id="card-heading">
                {label}
            </h2>
            <div className="ac-our-team-carousel-card-image">
                <img src={imgSrc} alt={label}></img>
            </div>
            <div className="ac-our-team-carousel-card-content">
                <span className="ac-our-team-carousel-card-description">{description}</span>
            </div>
        </div>
    );
}

OurTeamCarouselCard.propTypes = {
    // Card description (large body of text)
    description: PropTypes.string.isRequired,
    // Card image source
    imgSrc: PropTypes.string.isRequired,
    // Card label text
    label: PropTypes.string.isRequired,
};

OurTeamCarouselCard.defaultProps = {
    description: '',
    imgSrc: '',
    label: '',
};

export default OurTeamCarouselCard;
